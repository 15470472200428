import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Balancing Openness and Discretion"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>It's crucial to balance transparency and confidentiality to ensure successful outcomes in official visits and negotiations.</p>
                        </div>
                        <ImageBanner text={"“Balancing Openness and Discretion”"} image={'/images/projects/IMG_6758.jpeg'} />
                    </div>
                </>
            }
        />
    );
}