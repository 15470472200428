import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import React from 'react';
import BioNavigationItem from './BioNavigationItem';

export default function BioNavigation({ onChangeSection }) {
  return (
    <div className="bio-navigation">
      <Splide
        hasTrack={false}
        options={{
          gap: '40px',
          perPage: 5,
          pagination: false,
          breakpoints: {
            1240: {
              perPage: 4,
            },
            1024: {
              perPage: 3,
            },
            768: {
              perPage: 2,
            },
            600: {
              perPage: 1,
            },
          },
        }}
      >
        <SplideTrack>
        <SplideSlide>
            <BioNavigationItem
              date="2023"
              company="The Gambit gr celebrates"
              role="the completion of 10 projects "
              onClick={() => onChangeSection(0)}
              position="0"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2022"
              company="FNL"
              role="Partner"
              onClick={() => onChangeSection(1)}
              position="1"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2020"
              company="The Gambit gr"
              role="Founder"
              onClick={() => onChangeSection(2)}
              position="2"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2019"
              company="Master's Degree, Cornell University"
              role="Hospitality Management"
              onClick={() => onChangeSection(3)}
              position="3"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2017"
              company="Four Seasons Astir Palace"
              role="Hotel Manager"
              onClick={() => onChangeSection(4)}
              position="4"
            />
          </SplideSlide>
          <SplideSlide> 
            <BioNavigationItem 
              date="2013"
              company="BILL & COO MYKONOS"
              role="General Manager"
              onClick={() => onChangeSection(5)}
              position="5"
            />
          </SplideSlide>
          {/* <SplideSlide>
            <BioNavigationItem
              date="2007"
              company="Magazines & sites"
              role="Travel, Food & Art Editor"
              onClick={() => onChangeSection(6)}
              position="6"
            />
        </SplideSlide> */}
          <SplideSlide>
            <BioNavigationItem
              date="2008"
              company="Chandris Hotels"
              role="Executive Assistant Manager"
              onClick={() => onChangeSection(7)}
              position="7"
            />
          </SplideSlide>
          {/*<SplideSlide>
            <BioNavigationItem
              date="XXXX"
              company="The Hay-Adams Hotel"
              role="Operations Manager"
              onClick={() => onChangeSection(8)}
              position="8"
            />
      </SplideSlide> */}
          <SplideSlide>
            <BioNavigationItem
              date="2008"
              company="Imaret"
              role=""
              onClick={() => onChangeSection(10)}
              position="10"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2006"
              company="Tokyo - Seoul "
              role=""
              onClick={() => onChangeSection(11)}
              position="11"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2004"
              company="The Hay Adams Hotel"
              role="Operations Manager"
              onClick={() => onChangeSection(12)}
              position="12"
            />
          </SplideSlide>
          {/* <SplideSlide>
            <BioNavigationItem
              date="2002"
              company="Helios Hotels & Resorts"
              role="Front Office Manager"
              onClick={() => onChangeSection(9)}
              position="9"
            />
    </SplideSlide> */}
          <SplideSlide>
            <BioNavigationItem
              date="2002"
              company="Amphitryon Hotel"
              role="Front Office Manager"
              onClick={() => onChangeSection(13)}
              position="13"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2001"
              company="Annabelle Hotel"
              role="Assistant Front Office Manager"
              onClick={() => onChangeSection(14)}
              position="14"
            />
          </SplideSlide>
          <SplideSlide>
            <BioNavigationItem
              date="2000"
              company="Vedema Resort"
              role="Guest Services Manager"
              onClick={() => onChangeSection(15)}
              position="15"
            />
          </SplideSlide>
          
        </SplideTrack>

        <div className="splide__arrows ">
          <div
            className={`splide__arrow splide__arrow--prev bio-navigation-previous ${
              1 === 1 ? 'active' : ''
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.673"
              height="40.687"
              viewBox="0 0 16.673 40.687"
            >
              <path
                d="M4928,14975.375l15.471,19.648L4928,15014.657"
                transform="translate(4943.971 15015.359) rotate(180)"
                fill="none"
                stroke="#889aaa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </svg>
          </div>

          <div
            className={`splide__arrow splide__arrow--next bio-navigation-next ${
              1 === 1 ? 'active' : ''
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.673"
              height="40.687"
              viewBox="0 0 16.673 40.687"
            >
              <path
                d="M4928,14975.375l15.471,19.648L4928,15014.657"
                transform="translate(-4927.298 -14974.673)"
                fill="none"
                stroke="#889aaa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </svg>
          </div>
        </div>
      </Splide>
    </div>
  );
}
