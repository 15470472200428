import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"At Dinner by Heston, the dining experience strikes a harmonious balance"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>At Dinner by Heston, the dining experience strikes a harmonious balance, blending subtle innovations with a touch of history. It's...</p>
                        </div>
                        <ImageBanner text={"“At Dinner by Heston, the dining experience strikes a harmonious balance”"} image={'/images/food/IMG_3963.jpeg'} />
                    </div>
                </>
            }
        />
    );
}