import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Four Seasons George V Redefines the Hotel Experience"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p> Four Seasons George V, where luxury and timeless elegance converge, defining an extraordinary experience for the discerning traveler. </p>
                        </div>
                        <ImageBanner text={"“Four Seasons George V Redefines the Hotel Experience”"} image={'/images/travel/IMG_5119.jpeg'} />
                    </div>
                </>
            }
        />
    );
}