import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Tailored to Perfection, One&Only Elevates Luxury Hospitality Through Personalized Service"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>One & Only strives to extend an unparalleled level of service to each and every guest , tailoring the experience to meet individual needs and preferences.</p>
                        </div>
                        <ImageBanner text={"“Tailored to Perfection, One&Only Elevates Luxury Hospitality Through Personalized Service”"} image={'/images/travel/IMG_5925.jpeg'} />
                    </div>
                </>
            }
        />
    );
}