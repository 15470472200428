import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Through Art and Photography, JR Transforms Urban Landscapes into Narratives of Humanity"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Through the lens of his camera and the stroke of his art, JR captures the essence of humanity,turning streets into canvases and faces into stories.</p>
                        </div>
                        <ImageBanner text={"“Through Art and Photography, JR Transforms Urban Landscapes into Narratives of Humanity”"} image={'/images/art/IMG_4345.jpg'} />
                    </div>
                </>
            }
        />
    );
}