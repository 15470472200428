import Cookies from 'js-cookie';
import React from 'react';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { isIOS } from 'react-device-detect'


export default function VideoPlayerFull({ url, urlMobile, onEnd, onStart }) {
  const [urlToPlay, setUrlToPlay] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [urlLoaded, setUrlLoaded] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [muted, setMuted] = useState(false);
  const [playerWidth, setPlayerWidth] = useState(1920);
  const [playerHeight, setPlayerHeight] = useState(1080);
  const [isPaused, setIsPaused] = useState(false);
  const [navigationItemChanged, setNavigationItemChanged] = useState(false);
  const minMediaQuery = 600;

  const changeUrlToPlay = () => {
    console.log(url + ' ' + urlMobile);
    if (window.innerWidth <= minMediaQuery) {
      if(urlMobile !== urlToPlay) {
        setUrlToPlay(urlMobile);
      }
    } else {
      if (url !== urlToPlay) {
        setUrlToPlay(url);
      }
    }
    setUrlLoaded(true);
    setTimeout(function() {
      setVideoPlaying(true);
    }, 2000);
  };

  useEffect(() => {
    if(!urlLoaded) {
      if(isIOS === true) {
        console.log('IS IOS');
        setMuted(true);
      }
      changeUrlToPlay();
      Cookies.set('thegambit_video_paused', '0');
      const checkIfVideoPaused = setInterval(function() {
        const cookieForPause = Cookies.get('thegambit_video_paused');
        console.log(cookieForPause);
        if(parseInt(cookieForPause) !== 1) {
          setVideoPlaying(true);
        } else {
          setVideoPlaying(false);
        }
      }, 500);
      Cookies.set('thegambit_video_playing', 'yes');
      return () => {
        clearInterval(checkIfVideoPaused); // Clear the interval when the component unmounts
      };
    }
  }, []);

  const setPlayerDimensions = () => {
    // const windowWidth = window.innerWidth;
    // const windowHeight = window.innerHeight;
    const windowWidth = document.documentElement.clientWidth;
    const windowHeight = document.documentElement.clientHeight;
    const desktopAspectRatio = 16 / 9;
    const mobileAspectRatio = 9 / 16;
    const aspectRatio = windowWidth > minMediaQuery ? desktopAspectRatio : mobileAspectRatio;
    if (windowWidth / windowHeight > aspectRatio) {
      // Window is wider than 16:9
      const newHeight = windowWidth / aspectRatio;
      setPlayerWidth(windowWidth);
      setPlayerHeight(newHeight);
    } else {
      // Window is taller than 16:9
      const newWidth = windowHeight * aspectRatio;
      setPlayerWidth(newWidth);
      setPlayerHeight(windowHeight);
    }
  }

  useEffect(() => {
    if(!navigationItemChanged) {
      setNavigationItemChanged(true);
      var currentElementIndex = $('.topics-navigation-link.active').index('.topics-navigation-link');
      var nextElementIndex = currentElementIndex + 1;
      if(nextElementIndex < $('.topics-navigation-link').length) {
        $('.topics-navigation-link.active').removeClass('active');
        $('.topics-navigation-link').eq(nextElementIndex).addClass('active');
      } else {
        $('.topics-navigation-link.active').removeClass('active');
        $('.topics-navigation-link').eq(0).addClass('active');
      }
      setPlayerDimensions();
      window.addEventListener('resize', setPlayerDimensions);
      return () => {
        window.removeEventListener('resize', setPlayerDimensions);
      };
    }
  }, [urlToPlay]);

  return (
    <div className="full-width-all">
      <div className={`video-player ${videoEnded ? 'fadeout' : ''}`}>
        {!videoEnded &&
          <>
            <ReactPlayer
              url={urlToPlay}
              onStart={() => {
                onStart();
              }}
              onEnded={() => {
                setVideoEnded(true);
                setVideoPlaying(false);
                onEnd();
                Cookies.set('thegambit_video_playing', 'no');
              }}
              playing={videoPlaying}
              width={playerWidth}
              height={playerHeight}
              muted={muted}
              // config={{
              //   vimeo: {
              //     playerOptions: {
              //       background: 1
              //     }
              //   }
              // }}
              playsinline={true}
            />
          </>
        }
      </div>
      {!videoEnded &&
      <div className='mute-button' onClick={() => setMuted(!muted)}>{muted == true && <span></span>}
        <svg fill="#ffffff" width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.553 3.064A.75.75 0 0112 3.75v16.5a.75.75 0 01-1.255.555L5.46 16H2.75A1.75 1.75 0 011 14.25v-4.5C1 8.784 1.784 8 2.75 8h2.71l5.285-4.805a.75.75 0 01.808-.13zM10.5 5.445l-4.245 3.86a.75.75 0 01-.505.195h-3a.25.25 0 00-.25.25v4.5c0 .138.112.25.25.25h3a.75.75 0 01.505.195l4.245 3.86V5.445z"/><path d="M18.718 4.222a.75.75 0 011.06 0c4.296 4.296 4.296 11.26 0 15.556a.75.75 0 01-1.06-1.06 9.5 9.5 0 000-13.436.75.75 0 010-1.06z"/><path d="M16.243 7.757a.75.75 0 10-1.061 1.061 4.5 4.5 0 010 6.364.75.75 0 001.06 1.06 6 6 0 000-8.485z"/></svg>  
      </div>}
    </div>
  );
}
