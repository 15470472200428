import React, { useState } from 'react';
import MasonryItem from '../components/MasonryItem';
import Page from './Page';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import BackToTop from '../components/BackToTop';
import { useNavigate } from 'react-router';

const Moodboard = () => {

  const [activeSection, setActiveSection] = useState('');
  const [paragraphText, setParagraphText] = useState("Choose your favorite category");

  const navigate = useNavigate();

  const openArticle = (articleIndex) => {
    navigate('/moodboard/' + articleIndex);
  }

  const changeActiveSection = (section) => {
    if(activeSection !== section) {
      setActiveSection(section);

      // Update paragraph text based on the active section
      switch (section) {
        case 'art':
          setParagraphText("Through the lens of his camera and the stroke of his art, JR captures the essence of humanity, turning streets into canvases and faces into stories.");
          break;
        case 'food':
          setParagraphText("Gathered around a table, food becomes the catalyst for inspiration, fostering connections among individuals -  a shared experience where meals intertwined with moments, creating a tapestry of unity.");
          break;
        case 'travel':
          setParagraphText("Traveling is the art of discovering the extraordinary in the ordinary, and in moments like this, inspiration paints its masterpiece on the canvas of our minds.");
          break;
        case 'projects':
          setParagraphText("As a white label agency, we wield the strength to enhance brands, serving as a powerful tool in the hands of our partners. ");
          break;
        default:
          setParagraphText("Default text...");
          break;
      }


    } else {
      setActiveSection('');
      setParagraphText("Choose your favorite category");
    }
  }

  return (
    <Page
      extraClasses="moodboard-page"
      introInstant={true}
      outroInstant={true}
      content={
        <>
          <div className="content moodboard-content">
            <div className="masonry-column">
              <div className="moodboard-options">
                <div className="masonry-menu">
                  <div className={`masonry-menu-item ${activeSection === 'art' ? 'active' : ''}`} onClick={() => changeActiveSection('art')}>art</div>
                  <div className={`masonry-menu-item ${activeSection === 'food' ? 'active' : ''}`} onClick={() => changeActiveSection('food')}>food</div>
                  <div className={`masonry-menu-item ${activeSection === 'travel' ? 'active' : ''}`} onClick={() => changeActiveSection('travel')}>travel</div>
                  <div className={`masonry-menu-item ${activeSection === 'projects' ? 'active' : ''}`} onClick={() => changeActiveSection('projects')}>projects</div>
                </div>
                <div className="masonry-content">
                  <h2>Moodboard</h2>
                  <p>
                    {paragraphText}
                  </p>
                </div>
              </div>
              <ResponsiveMasonry columnsCountBreakPoints={{0: 1}}>
                <Masonry gutter='0px' columnsCount={1}>
                  {/* <MasonryItem isActive={activeSection === 'travel' || activeSection === ''} text={"Το Lorem Ipsum είναι απλά ένα τυφλό κείμενο χωρίς νόημα"}  image={"/images/moodboard_environment_1.jpg"} onClick={() => openArticle(1)} />  */}


                </Masonry>
              </ResponsiveMasonry>
              <ResponsiveMasonry 
                columnsCountBreakPoints={{
                  0: 1,
                  1200: 2
                }}
              >
                <Masonry gutter='40px'>
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"Embarking on a culinary journey at Eleven Madison Park is a sensory odyssey, where each dish is a masterpiece meticulously..."}  image={"/images/food/IMG_3710.jpeg"} onClick={() => openArticle(1)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'projects' || activeSection === ''} text={"Embarking on a project from its inception provides the unique opportunity to shape greatness from the groundup."}  image={"/images/projects/four-seasons123.jpg"} onClick={() => openArticle(9)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'art' || activeSection === ''} text={"Through the lens of his camera and the stroke of his art, JR captures the essence of humanity,turning streets..."}  image={"/images/art/IMG_4345.jpg"} onClick={() => openArticle(2)} />

                  {/* <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"Το Lorem Ipsum είναι απλά ένα τυφλό κείμενο χωρίς νόημα"}  image={"/images/moodboard_environment_5.jpg"} onClick={() => openArticle(3)} /> */}
                  {/* <MasonryItem isActive={activeSection === 'travel' || activeSection === ''} text={"Το Lorem Ipsum είναι απλά ένα τυφλό κείμενο χωρίς νόημα"}  image={"/images/moodboard_environment_7.jpg"} onClick={() => openArticle(4)} /> */}
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"Traveling is the art of discovering the extraordinary in the ordinary, and in moments like this..."}  image={"/images/food/IMG_8051.jpeg"} onClick={() => openArticle(10)} />
                  
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"The Energy of New York, with its iconic skyline, diverse people and constant street noise..."}  image={"/images/travel/12E343B2.jpg"} onClick={() => openArticle(3)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={" In a small tavern in Elefsina where the artful simplicity of each dish forms a humble symphony..."}  image={"/images/food/IMG_4171.jpg"} onClick={() => openArticle(11)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'projects' || activeSection === ''} text={"Infusing a building with soul is the transformative touch that turns mere structure into..."}  image={"/images/projects/IMG_8087.jpeg"} onClick={() => openArticle(4)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"The Energy of New York, with its iconic skyline, diverse people and constant street noise..."}  image={"/images/travel/IMG_2784.jpeg"} onClick={() => openArticle(12)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"Central, Lima by Virgilio Martinez: A culinary masterpiece celebrating Peru's breathtaking diversity..."}  image={"/images/food/IMG_6964.jpeg"} onClick={() => openArticle(5)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"Posada Margarita, Nestled right on the beach, this tranquil haven blends the beauty of Tulum with..."}  image={"/images/travel/IMG_5124.jpeg"} onClick={() => openArticle(13)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"Savor the authenticity of Greek culinary traditions through rustic, traditional food - an exploration..."}  image={"/images/food/IMG_1825.jpeg"} onClick={() => openArticle(6)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"Posada Margarita, Nestled right on the beach, this tranquil haven blends the..."}  image={"/images/travel/IMG_5131.jpeg"} onClick={() => openArticle(14)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'projects' || activeSection === ''} text={"It's crucial to balance transparency and confidentiality to ensure successful..."}  image={"/images/projects/IMG_6758.jpeg"} onClick={() => openArticle(7)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={" Four Seasons George V, where luxury and timeless elegance converge, defining an..."}  image={"/images/travel/IMG_5119.jpeg"} onClick={() => openArticle(15)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'projects' || activeSection === ''} text={"We stand prepared to support you in navigating challenges, bridging the gap..."}  image={"/images/projects/IMG_1589.jpeg"} onClick={() => openArticle(8)} />
                </Masonry>
              </ResponsiveMasonry>
            </div> 
            <div className="masonry-column">
              <ResponsiveMasonry columnsCountBreakPoints={{
                0: 1,
                1200: 2
              }}>
                <Masonry gutter='40px'>
                <MasonryItem fontSize={'small'} isActive={activeSection === 'projects' || activeSection === ''} text={"While others see an abandoned building, we at The Gambit gr envision an opportunity awaiting the right approach and transformation."}  image={"/images/projects/IMG_2358.jpeg"} onClick={() => openArticle(16)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"At Dinner by Heston, the dining experience strikes a harmonious balance, blending subtle innovations with a touch of history. It's a meal..."}  image={"/images/food/IMG_3963.jpeg"} onClick={() => openArticle(25)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'art' || activeSection === ''} text={"Picture ancient ruins surrounded by remnants of Minoan architecture, with a warm Mediterranean sun casting shadows on the weathered..."}  image={"/images/art/Snapseed.jpeg"} onClick={() => openArticle(17)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"Closing a personalised dinner on the other side of the Atlantic with humor establishes a connection that goes beyond cuisine..."}  image={"/images/food/IMG_3748.jpeg"} onClick={() => openArticle(26)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"At The Fat Duck, the culinary voyage curated by Heston Blumental unfolds, inviting you into a realm where gastronomy becomes an..."}  image={"/images/food/IMG_4106.jpeg"} onClick={() => openArticle(18)} /> 
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'art' || activeSection === ''} text={"The Winged Victory of Samothrace, the celebrated ancient Greek sculpture currently displayed at the Louvre Museum in Paris. Created..."}  image={"/images/art/Snapseed 2.jpeg"} onClick={() => openArticle(27)} />
                  {/* <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"Το Lorem Ipsum είναι απλά ένα τυφλό κείμενο χωρίς νόημα"}  image={"/images/moodboard_environment_3.jpg"} onClick={() => openArticle(7)} /> */}
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"Perched on the rugged cliffs, Casa de Chá da Boa Nova not only tantalizes the taste buds but also captivates the eyes with its..."}  image={"/images/food/IMG_6668.jpeg"} onClick={() => openArticle(19)} />
                  {/* <MasonryItem isActive={activeSection === 'travel' || activeSection === ''} text={"Το Lorem Ipsum είναι απλά ένα τυφλό κείμενο χωρίς νόημα"}  image={"/images/moodboard_environment_2.jpg"} onClick={() => openArticle(8)} /> */}
                  {/* <MasonryItem isActive={activeSection === 'food' || activeSection === ''} text={"Το Lorem Ipsum είναι απλά ένα τυφλό κείμενο χωρίς νόημα"}  image={"/images/moodboard_food_1.jpg"} onClick={() => openArticle(9)} /> */}
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"Surrounded by unparalleled panoramic views of the White House, Lafayette Square, and St. John's Church, The Hay-Adams is..."}  image={"/images/travel/IMG_3951.jpeg"} onClick={() => openArticle(28)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'food' || activeSection === ''} text={"At the table, people connect, inspire, argue and agree. A diverse symphony of interactions that, in the end, builds relationships..."}  image={"/images/food/IMG_1617.jpeg"} onClick={() => openArticle(20)} />                  
                  {/* <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"Το Lorem Ipsum είναι απλά ένα τυφλό κείμενο χωρίς νόημα"}  image={"/images/moodboard_environment_6.jpg"} onClick={() => openArticle(10)} /> */}

                  
                  
                </Masonry>
              </ResponsiveMasonry>
              <ResponsiveMasonry columnsCountBreakPoints={{0: 1,
            1200: 2}}>
                <Masonry gutter='40px'>
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"Staying at La Mansiòn, Four Seasons Buenos Aires, a fusion of sophistication and Argentine charm, where timeless elegance meets modern..."}  image={"/images/travel/IMG_5383.jpeg"} onClick={() => openArticle(21)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'art' || activeSection === ''} text={"IPhotography captures moments gone forever, yet preserves them eternally. The profound messages..."}  image={"/images/art/432C3B35.jpg"} onClick={() => openArticle(29)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"A Stay at Mandarin Oriental prompts a reevaluation of luxury hospitality standards. Photo: The Mandarin Oriental Costa Navarino, Greece"}  image={"/images/travel/IMG_1408.jpeg"} onClick={() => openArticle(22)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'art' || activeSection === ''} text={"Art is a subjective and diverse form of human expression. It encompasses a wide range of creative activities and includes visual arts..."}  image={"/images/art/IMG_2025.jpeg"} onClick={() => openArticle(30)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"One & Only strives to extend an unparalleled level of service to each and every guest , tailoring the experience to meet..."}  image={"/images/travel/IMG_5925.jpeg"} onClick={() => openArticle(23)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={" Copacabana Palace: A historic gem where timeless glamour meets the rhythm of Rio. Since 1923, the iconic hotel has stood as a testament of luxury..."}  image={"/images/travel/IMG_3432.jpeg"} onClick={() => openArticle(31)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'art' || activeSection === ''} text={"In the curated tapestry of time and culture, the Benaki Museum unforlds narratives that inspire admiration..."}  image={"/images/art/IMG_5857.jpeg"} onClick={() => openArticle(24)} />
                  <MasonryItem fontSize={'small'} isActive={activeSection === 'travel' || activeSection === ''} text={"A sanctuary of luxury and history, The Monasterio, a Belmond Hotel,where the sacred echos of a 16th century..."}  image={"/images/travel/IMG_7989.jpeg"} onClick={() => openArticle(32)} />

                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
          <BackToTop element={".moodboard-content"} />
        </>
      }
    />
  );
};

export default Moodboard;
