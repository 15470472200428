import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Traveling is the art of discovering the extraordinary in the ordinary"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Traveling is the art of discovering the extraordinary in the ordinary, and in moments like this, inspiration paints its masterpiece on the canvas of our minds.</p>
                        </div>
                        <ImageBanner text={"“Traveling is the art of discovering the extraordinary in the ordinary”"} image={'/images/food/IMG_8051.jpeg'} />
                    </div>
                </>
            }
        />
    );
}