import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Posada Margarita: A Tranquil Oasis of Authentic Mexican Hospitality"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Posada Margarita, Nestled right on the beach, this tranquil haven blends the beauty of Tulum with the warmth of Mexican hospitality, promising an unforgettable retreat of seaside serenity and authentic charm.</p>
                        </div>
                        <ImageBanner text={"“Posada Margarita: A Tranquil Oasis of Authentic Mexican Hospitality”"} image={'/images/travel/IMG_5124.jpeg'} />
                    </div>
                </>
            }
        />
    );
}