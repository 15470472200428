import React from 'react';
import { useNavigate } from 'react-router';
import TopicsNavigation from '../../components/TopicsNavigation';
import VideoPlayerFull from '../../components/VideoPlayerFull';
import Page from '../Page';
import { useState } from 'react';
import AfterVideo from '../../components/AfterVideo';
import PageIntro from '../PageIntro';

export default function RealEstateConsultancy() {

  const navigate = useNavigate();
  const [showNavigation, setShowNavigation] = useState(false);
  const [hideIntro, setHideIntro] = useState(false);

  return (
    <Page
      outroInstant={true}
      content={
        <>
          <div className={`video-intro ${hideIntro ? 'hide-intro' : ''}`}>
            <PageIntro content={
              <>
                <div className="topic-intro-title">
                  Real<br></br>estate<br></br>Consultancy
                </div>
              </>
            } />
          </div>
          <VideoPlayerFull
            url="https://vimeo.com/872587014"
            urlMobile="https://vimeo.com/877892526"
            onEnd={() => setShowNavigation(true)}
            onStart={() => {
              setTimeout(function() {
                setHideIntro(true);
              }, 100);
            }}
          />
          <AfterVideo
            text={
              <>
                Find your perfect<br></br>property solution
              </>
            }
            isShowing={showNavigation}
          />
          <TopicsNavigation isShown={showNavigation} />
        </>
      }
    />
  );
}
