import Page from "./Page";

export default function NotFoundPage() {
    return (
        <Page
            content={
                <div className="content">
                    <h2>The page you are looking for does not exist.</h2>
                </div>        
            }
            outroInstant={true}
        />
    );
}