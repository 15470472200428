import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"The Benaki Museum as a Beacon of Inspiration"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>In the curated tapestry of time and culture, the Benaki Museum unforlds narratives that inspire admiration, weaving history and art into a captivating masterpiece. Hence providing any kind of support is the least we can do. </p>
                        </div>
                        <ImageBanner text={"“The Benaki Museum as a Beacon of Inspiration”"} image={'/images/art/IMG_5857.jpeg'} />
                    </div>
                </>
            }
        />
    );
}