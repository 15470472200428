import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import React from 'react';
import BioNavigationItemMobile from './BioNavigationItemMobile';

export default function BioNavigationMobile({ onChangeSection, activeSection, onClosedAll }) {
    return (
        <div className="bio-navigation-mobile mobile-element">
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2023"
                company="The Gambit gr celebrates"
                role="the completion of 10 projects "
                content={
                    <>
                        <p>
                            In 2023, The Gambit GR achieved its first milestone. By the end of the year, we celebrated our successful participation in 10 projects across Hospitality, Real Estate, Luxury Retail, Business Coaching and Arts. These fields were assigned to us by independent owners, investors, funds and family offices in Greece, Cyprus, UK & USA.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(0)}
              position="0"
                active={activeSection === 0}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2022"
                company="FNL"
                role="Partner"
                content={
                    <>
                        <p>
                            The FnL Guide, is a luxury lifestyle guide in Greece covering various aspects such as bars, restaurants, hotels, travel, retail and more since 2011. An online source of the most accurate and up-to-date details on luxury experiences in Greece.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(1)}
                position="1"
                active={activeSection === 1}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2020"
                company="The Gambit gr"
                role="Founder"
                content={
                    <>
                        <p>
                             A white label brand development agency was born.
                         The Gambit Gr is a business partner, a brain-storming
                        channel that filters all processes, a third eye that provides feedback 
                        through every step of the way.
                        The Gambit gr vision -shaped by personal journeys and professional
                        experiences- is to become the reliable partner in every new opportunity, <br></br><br></br>to be part of the long-term and sustainable<br></br><br></br>

                        development of your brand. A team of specialists in
                        their fields is always at your disposal for consultation
                        or for complete project management
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(2)}
                position="2"
                active={activeSection === 2}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2019"
                company="Master's Degree, Cornell University"
                role="Hospitality Management"
                content={
                    <>
                        <p>
                             The Master of Management in Hospitality (MMH) program is a graduate-level business program firmly anchored in the hospitality industry.  The MMH curriculum focuses on understanding and experiencing the business of hospitality management, rather than general management.<br></br><br></br>

                          Cornell is a privately endowed research university and a partner of the State University of New York. Ithaca, NY. Founded 1865
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(3)}
                position="3"
                active={activeSection === 3}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2017"
              company="Four Seasons Astir Palace"
              role="Hotel Manager"
                content={
                    <>
                        <p>
                             A multi-million euro revamp of the luxury Astir Palace hotel on the Athens Riviera brought the Four Seasons brand to Greece for the first time. The hotel, bigest Four Seasons in EMEA, includes 300 rooms, suites and executive bungalows, first-class spa facilities, fitness centers and tennis academy, a state-of-the-art conference center, two outdoor pools, one indoor pool, and eight eateries, lounges and bars.<br></br><br></br>

                          The 5-star hotel is situated in a perfect location, for travelers who desire the excitement of the city but enjoy the seclusion of private beaches on a sunny peninsula. The Four Seasons is built on 75 acres of land, filled with Greek hospitality.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(4)}
                position="4"
                active={activeSection === 4}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2008"
              company="BILL & COO MYKONOS"
              role="General Manager"
                content={
                    <>
                    <p>Mykonos, Greece<br></br><br></br>
                        2014 October, Bill & Coo, Best Coastal Boutique Hotel, Europe, Boutique Hotel Awards, London<br></br><br></br>
                        2016 February, Bill & Coo, Best Boutique Hotel, World Tourism Forum, Istanbul<br></br><br></br>
                        2017 Opening The Beefbar Mykonos</p><br></br>
                        <p>
                             The opening of the first Bill & Coo hotel, Bill & Coo Suites and Lounge, in 2006 on the edge of Mykonos Town with its views over the Aegean Sea and incredible sunsets, brought a new level of intimate luxury to the island. The hotel aimed, and very soon achieved, to become the place where the guest would be at the centre of everything, where every experience would be tailored individually. In 2016, Bill & Coo expanded to a second property, the adults-only Bill & Coo Coast Suites, located on a beautiful golden stretch of Agios Ioannis beach, just a seven-minute drive from Bill & Coo Suites and Lounge. This intimate hideaway opened up a new way for guests to enjoy the Bill & Coo way of life in a space designed for tranquil relaxation. 
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(5)}
                position="5"
                active={activeSection === 5}
            />
            {/*<BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2007"
                company="Magazines & sites"
                role="Travel, Food & Art Editor"
                content={
                    <>
                        <p>
                            Lorem Ipsum is simply dummy text of t he printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(6)}
                position="6"
                active={activeSection === 6}
            /> */}
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2008"
              company="Chandris Hotels"
              role="Executive Assistant Manager"
                content={
                    <>
                        <p>
                            For over 60 years, Chandris Hotels uphold the famous Greek concept of hospitality providing their guests with exceptional service and unforgettable experiences. Continuing this tradition, the brand ensures excellence and offers the highest standards on every conceivable level for their guests. With a passion for detail and personal attention to each visitor's needs, Chandris Hotels welcome their esteemed guests to a sophisticated combination of high aesthetics and technology, discreet luxury and relaxation. We blend your hotel experience with local history and deep cultural values.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(7)}
                position="7"
                active={activeSection === 7}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2008"
                company="Imaret"
                role=""
                content={
                    <>
                        <p>
                            An intimate luxurious hospitality property, with only 18 keys, located within the homonymous historical monument. Built in 1817 right in the middle of the historical district of Kavala, in Northern Greece, it is an unexpected oasis of tranquility and superior service. The monument is a rare example in Europe, a masterpiece of late Ottoman architecture. The variety of decoration, the quality of the delicate structures, the interaction between the indoor and outdoor spaces, and the adaptability of the functional structures, create an original complex of high aesthetic and cultural value. Each detail is a tribute to absolute luxury. Lavish materials, all specially made, changing from rich velvet and silks in the winter to elegant handmade French linen in summer, handmade oriental carpets covering waxed chestnut floors. A large variety of exclusive toiletries and candles create Imaret's indisputably essential atmosphere. 
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(10)}
                position="10"
                active={activeSection === 10}
            />
            {/* <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2004"
                company="The Hay-Adams Hotel"
                role="Operations Manager"
                content={
                    <>
                        <p>
                            Lorem Ipsum is simply dummy text of t he printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(8)}
                position="8"
                active={activeSection === 8}
            /> */}
            {/* <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2002"
                company="Helios Hotels & Resorts"
                role="Front Office Manager"
                content={
                    <>
                        <p>
                            Lorem Ipsum is simply dummy text of t he printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(9)}
                position="9"
                active={activeSection === 9}
            /> */}
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2006"
              company="Tokyo - Seoul "
              role=""
                content={
                    <>
                        <p>
                            Two Years of experience in the hospitality industry in Asia, primarily in Tokyo, Japan and Seoul, South Korea, across various enterprises. Immersed in a unique blend of traditions, rich history, profound knowledge and cutting edge technology.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(11)}
                position="11"
                active={activeSection === 11}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2004"
              company="The Hay Adams Hotel "
              role="Operations Manager"
                content={
                    <>
                        <p>
                            Surrounded by unparalleled panoramic views of the White House, Lafayette Square, and St. John's Church, The Hay-Adams is at the epicenter of Downtown Washington, DC's most iconic destinations. Originally designed and built as a residential hotel in the 1920s, The Hay-Adams stands today as one of the most historic hotels in Washington, DC, with refined services and offerings that match our prestigious past. With 5-star accommodations, including 124 refined guest rooms and 21 deluxe suites, exceptionally-serviced event spaces for meetings and weddings, and world-class dining destinations, The Hay-Adams is a luxury Downtown DC hotel unlike any other. Being loyal to its promise — to exceptional guest experiences, to the environment, and to maintaining the most inviting luxury accommodations in Washington, DC— The Hay-Adams prides itself on its status as the region's most prestigious hotel destination.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(12)}
                position="12"
                active={activeSection === 12}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2002"
              company="Amphitryon Hotel"
              role="Front Office Manager"
                content={
                    <>
                        <p>
                            A contemporary boutique hotel in Nafplio, the first capital of Modern Greece, renowned for lodging famous visitors from all over the world. Perched near the castle of Akronafplia, the Amphitryon is a hotel of absolute beauty. There are 42 luxury rooms and three suites, all overlooking the bay of Nafplion and the island of Bourtzi, all with great amenities like remote-controlled mattresses, private sea view terraces  and mouthwatering breakfast. 
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(13)}
                position="13"
                active={activeSection === 13}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2001"
              company="Annabelle Hotel"
              role="Assistant Front Office Manager"
                content={
                    <>
                        <p>
                           Under the soulful care of the Michaelides family since 1985, Annabelle shines as the grand dame of 5-star hotels in Paphos. Annabelle epitomises the warmth of Cyprus, the beauty of its nature, and its hands-on culture of care and conscientiousness.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(14)}
                position="14"
                active={activeSection === 14}
            />
            <BioNavigationItemMobile
                onClosedAll={onClosedAll}
                date="2000"
              company="Vedema Resort"
              role="Guest Services Manager"
                content={
                    <>
                        <p>
                            Vedema Resort, located in the unspoiled village of Megalochori, inspires guests to fully immerse into the simple, slow-paced life of the island and addresses global explorers seeking to experience the island like locals do.<br /><br />
                          Remaining true to its promise to offer understated, yet transformative luxury, Vedema is the ultimate and authentic village retreat.
                        </p>   
                    </>
                }
                onClick={() => onChangeSection(15)}
                position="15"
                active={activeSection === 15}
            />
        </div>
    );
}
