import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Where Abandoned Buildings Become Opportunities"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>While others see an abandoned building, we at The Gambit gr envision an opportunity awaiting the right approach and transformation.</p>
                        </div>
                        <ImageBanner text={"“Where Abandoned Buildings Become Opportunities”"} image={'/images/projects/IMG_2358.jpeg'} />
                    </div>
                </>
            }
        />
    );
}