import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import React from 'react';
import { useEffect, useState } from 'react';
import TopicsNavigationItem from './TopicsNavigationItem';

export default function TopicsNavigation({ isShown = true }) {

  return (
    <div className={`topics-navigation ${isShown === false ? 'hidden' : ''}`}>
      <Splide
        hasTrack={false}
        options={{
          gap: '20px',
          autoWidth: true,
          pagination: false,
          arrows: false,
        }}
      >
        <SplideTrack>
          <SplideSlide>
            <TopicsNavigationItem
              text={'BRAND\nDEVELOPMENT'}
              path="/services/brand-development"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'BUSINESS\nCOACHING'}
              path="/services/business-coaching"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'BUSINESS\nEXPANSION'}
              path="/services/business-expansion"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'BUSINESS\nREPRESENTATION'}
              path="/services/business-representation"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'CO-\nBRANDING'}
              path="/services/co-branding"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'CORPORATE\nAFFAIRS'}
              path="/services/corporate-affairs"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'CRISIS\nMANAGEMENT'}
              path="/services/crisis-management"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'PROJECT\nMANAGEMENT'}
              path="/services/project-management"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'QUALITY\nASSURANCE'}
              path="/services/quality-assurance"
            />
          </SplideSlide>
          <SplideSlide>
            <TopicsNavigationItem
              text={'REAL ESTATE\nCONSULTANCY'}
              path="/services/real-estate-consultancy"
            />
          </SplideSlide>
        </SplideTrack>
      </Splide>
    </div>
  );
}
