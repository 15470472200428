import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Overcoming Distances, Bridging Gaps"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>We stand prepared to support you in navigating challenges, bridging the gap between what may seem too close or too far</p>
                        </div>
                        <ImageBanner text={"“Overcoming Distances, Bridging Gaps”"} image={'/images/projects/IMG_1589.jpeg'} />
                    </div>
                </>
            }
        />
    );
}