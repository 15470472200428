import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Frozen in Motion: The Winged Victory of Samothrace and Its Captivating Dynamism"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>The Winged Victory of Samothrace, the celebrated ancient Greek sculpture currently displayed at the Louvre Museum in Paris. Created in the 2nd century BC, the statue commemorates a naval victory.</p>
                            <p>The winged figure, believed to be the Greek goddess Nike, stands on the prow of a ship, capturing a dynamic moment. The missing head and arms contribute to the statue's mystique while its intricate details showcase the Hellenistic sculptural style.</p>
                            <p>Each and every time I see The Victory of Samothrace is impossible not to admire its dramatic composition and the sense of movement it conveys. Snapseed 2</p>
                        </div>
                        <ImageBanner text={"“Frozen in Motion: The Winged Victory of Samothrace and Its Captivating Dynamism”"} image={'/images/art/Snapseed 2.jpeg'} />
                    </div>
                </>
            }
        />
    );
}