import React from 'react';
import BackToTop from './BackToTop';
import Page from '../pages/Page';
import { useNavigate } from 'react-router';

export default function Article({ title, date, featuredImage, content }) {

    const navigate = useNavigate();

    return (
        <>
            <Page 
                extraClasses={`white-bg`}
                // intro={<div className='topic-intro-title'>Article #1</div>}
                introInstant={true}
                content={
                    <div className="article">
                        <div className="article-header">
                            <h1>{title}</h1>
                            <div className='article-date'>
                                <span>{date}</span>
                                <div className='inner-page-back' onClick={() => navigate('/moodboard')}>Back to Moodboard</div>
                            </div>
                        </div>
                        {/* <div className="article-featured-image"><img src={featuredImage} /></div> */}
                        <div className="article-content">{content}</div>
                    </div>
                }
                outroInstant={true}
            />
            <BackToTop element={".article"} />
        </>
    )
}