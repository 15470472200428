import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"A small tavern in Elefsina where the artful simplicity of each dish forms a humble symphony"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>In a small tavern in Elefsina where the artful simplicity of each dish forms a humble symphony, allowing the treasures of the sea to shine.</p>
                        </div>
                        <ImageBanner text={"“A small tavern in Elefsina where the artful simplicity of each dish forms a humble symphony”"} image={'/images/food/IMG_4171.jpg'} />
                    </div>
                </>
            }
        />
    );
}