import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

export default function TopicsNavigationItem({ path, text }) {
  let location = useLocation();
  const locationPathname = location.pathname;
  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  };
  return (
    <div
      className={`topics-navigation-link ${
        locationPathname == path ? 'active' : ''
      }`}
      onClick={() => {
        if(locationPathname === path) {
          window.location.reload();
        } else {
          navigateTo(path);
        }
      }}
    >
      {text}
    </div>
  );
}
