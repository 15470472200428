import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"A diverse symphony of interactions that, in the end, builds relationships that withstand the test of shared moments"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>At the table, people connect, inspire, argue and agree. A diverse symphony of interactions that, in the end, builds relationships that withstand the test of shared moments and differing perspectives. Opora Country Living, Nafplio</p>
                        </div>
                        <ImageBanner text={"“A diverse symphony of interactions that, in the end, builds relationships that withstand the test of shared moments”"} image={'/images/food/IMG_1617.jpeg'} />
                    </div>
                </>
            }
        />
    );
}