import React from 'react';
import { useState, useEffect } from 'react';
import Page from './Page';

const TermsOfUse = () => {

  return (
      <Page extraClasses={"terms-policy-page"} content={
        <div>
       <div class="terms-head">
          Terms Of Use
        </div>
      <div className='terms-row'>
        <div className='terms-col-md-6'>
          <p>Panagiotis Sopiadis has overseen a handful of hotel operations over the course of his hospitality career in Greece, but none on par with the one he oversees now. “This is not just any property,” he says of Four Seasons Astir Palace Hotel Athens, which he joined as opening Hotel Manager in 2018. “It has been hosting the most i mportant people on the planet for 50 or 60 years. History has happened here. Being part of a Four Seasons rebirth of a legend, hospitality-wise and career-wise – what more could I have wanted?” What more, indeed. At the heart of Panagiotis’s wide-ranging role is taking care of people: Team members and guests, to be sure, but also locals out for a dining experience or a spa day they won’t soon forget, and vendors whose products and services are essential to the offerings. Ensuring that the Hotel lives up to its 5-star billing is a responsibility Panagiotis takes seriously. “We can’t leave anything to luck. Our operation has to run as close to perfection as possible with unique offerings around the clock.” While Four Seasons is a part of life for many guests, it’s a major investment for others, he continues.</p>
          <p>“All it takes is a bad cup of coffee to ruin a perfect time. We shouldn’t allow that.” Finding the right people and the right way to motivate them was key to delivering that experience. Panagiotis says he focuses more than half of his day on helping the Hotel’s 700-member team feel comfortable in their roles. “Some people need space; others need help. I’m here to give everyone all the tools they need to perform at any given moment.” Astir Palace has long been a landmark drawing international elite and local clientele to splendour along the Aegean Sea, including pine-dotted and scenic landscaped gardens and a beachfront – all upgraded by Four Seasons. Panagiotis believes the reflagging was well timed: “Athens is on the upswing as a destination,</p>
        </div>
        <div className='terms-col-md-6'>
          <p>Panagiotis Sopiadis has overseen a handful of hotel operations over the course of his hospitality career in Greece, but none on par with the one he oversees now. “This is not just any property,” he says of Four Seasons Astir Palace Hotel Athens, which he joined as opening Hotel Manager in 2018. “It has been hosting the most i mportant people on the planet for 50 or 60 years. History has happened here. Being part of a Four Seasons rebirth of a legend, hospitality-wise and career-wise – what more could I have wanted?” What more, indeed. At the heart of Panagiotis’s wide-ranging role is taking care of people: Team members and guests, to be sure, but also locals out for a dining experience or a spa day they won’t soon forget, and vendors whose products and services are essential to the offerings. Ensuring that the Hotel lives up to its 5-star billing is a responsibility Panagiotis takes seriously. “We can’t leave anything to luck. Our operation has to run as close to perfection as possible with unique offerings around the clock.”</p>
          <p>While Four Seasons is a part of life for many guests, it’s a major investment for others, he continues. “All it takes is a bad cup of coffee to ruin a perfect time. We shouldn’t allow that.” Finding the right people and the right way to motivate them was key to delivering that experience. Panagiotis says he focuses more than half of his day on helping the Hotel’s 700-member team feel comfortable in their roles. “Some people need space; others need help. I’m here to give everyone all the tools they need to perform at any given moment.” Astir Palace has long been a landmark drawing international elite and local clientele to splendour along the Aegean Sea, including pine-dotted and scenic landscaped gardens and a beachfront – all upgraded by Four Seasons. Panagiotis believes the reflagging was well timed: “Athens is on the upswing as a destination,</p>
        </div>
      </div>
    </div>
    }
      />
    );
};

export default TermsOfUse;
