import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Closing a personalised dinner on the other side of the Atlantic with humor establishes a connection that goes beyond cuisine"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Closing a personalised dinner on the other side of the Atlantic with humor establishes a connection that goes beyond cuisine, creating a delightful commitment to return for more cherished moments. Eleven Madison Park, NY</p>
                        </div>
                        <ImageBanner text={"“Closing a personalised dinner on the other side of the Atlantic with humor establishes a connection that goes beyond cuisine”"} image={'/images/food/IMG_3748.jpeg'} />
                    </div>
                </>
            }
        />
    );
}