import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"At The Fat Duck, the culinary voyage curated by Heston Blumental unfolds"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>At The Fat Duck, the culinary voyage curated by Heston Blumental unfolds, inviting you into a realm where gastronomy becomes an immersive experience, and every bite is a brushstroke on the canvas of innovation.</p>
                        </div>
                        <ImageBanner text={"“At The Fat Duck, the culinary voyage curated by Heston Blumental unfolds”"} image={'/images/food/IMG_4106.jpeg'} />
                    </div>
                </>
            }
        />
    );
}