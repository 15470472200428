import * as React from 'react';
import { useEffect, useState } from 'react';

import gsap from 'gsap';

export default function PageIntro({ content, instant }) {
  const [animationPlayed, setAnimationPlayed] = useState(false);

  useEffect(() => {
    if(instant !== true) {
      if (!animationPlayed) {
        setAnimationPlayed(true);

        const tl = gsap.timeline();

        tl.to(
          {},
          {
            delay: 0.25,
          }
        );

        tl.to('.page-intro .inner', {
          opacity: 1,
          duration: 0.5,
        });

        // Animation to fade in the intro
        tl.to('.page-intro', {
          opacity: 1,
          duration: 0.5,
        });

        // Delay for 5 seconds
        tl.to(
          {},
          {
            delay: 1.5,
          }
        );

        tl.to('.page-intro', {
          opacity: 0,
          pointerEvents: 'none',
          duration: 0.5,
        });

      }

    } else {
      const tl = gsap.timeline();
      tl.to('.page-intro', {
        opacity: 0,
        pointerEvents: 'none',
        duration: 0,
      });
    }
  }, [animationPlayed]);

  return (
    <>
      {instant !== true ? (
      <div className="page-intro">
        <div className="inner">{content}</div>
      </div> ) : (<div id="intro-no-delay"></div>)}
    </>
  );
}
