import React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import Logo from './components/Logo';
import NavigationItem from './components/NavigationItem';
import Contact from './components/Contact';
import Cookies from 'js-cookie';
import Topics from './pages/Topics';

const Navigation = () => {
  const location = useLocation();
  const locationPathname = location.pathname;
  const [menuOpen, setMenuOpen] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const navigate = useNavigate();
  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const navigateTo = (path) => {
    navigate(path);
  };
  const resumeVideo = () => {
    Cookies.set('thegambit_video_paused', '0');
  }
  const stopVideo = () => {
    Cookies.set('thegambit_video_paused', '1');
  }
  return (
    <>
      <header
        className={`${
          locationPathname === '/bio' || locationPathname.includes('/services/') || locationPathname.includes('/intro')
            ? 'transparent'
            : ''
        }`+`${
          locationPathname === '/privacy-policy'?'non-fixed-menu':''
        }`}
      >
        <div 
          className="nav-logo" 
          onClick={() => {
            setShowContact(false); 
            setMenuOpen(false); 
            if(locationPathname !== '/services') {
              // if( jQuery('.page-outro').legth > 0 ) {
              //   jQuery('.page-outro').html(<Topics />);
              // } else {
              //   console.log('triggered change');
              //   jQuery('.page').append('<div class="page-outro"></div>');
              //   jQuery('.page-outro').html(<Topics />);
              //   console.log('.page-outro').html();
              // }
              navigateTo('/services');
            } else {
              window.location.reload();
            }
          }}>
          <Logo />
        </div>
        <div 
          className="navigation-dropdown-open" 
          onClick={() => {
            if(!menuOpen) {
              if(!showContact) {
                openMenu();
                stopVideo();
              } else {
                setShowContact(false);
                resumeVideo();
              }
            } else {
              setShowContact(false);
              setMenuOpen(false);
              resumeVideo();
            }
          }}>
          <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none">
            <path
              d="M5 15H19M5 9H19"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          MENU
        </div>
      </header>
      <div className={`navigation-dropdown ${menuOpen ? 'active' : ''}`}>
        <nav>
          <div
            className="navigation-dropdown-close"
            onClick={() => {
              closeMenu();
              resumeVideo();
            }}
          >
            CLOSE
          </div>
          <div className="row">
            <div className="column">
              <NavigationItem
                text="Bio"
                path="/bio"
                onClickFunction={() => {
                  setMenuOpen(false);
                }}
              />
              <NavigationItem
                text="Services"
                path="/services"
                onClickFunction={() => {
                  setMenuOpen(false);
                }}
              />
            </div>
            <div className="column">
              <NavigationItem
                text="Moodboard"
                path="/moodboard"
                onClickFunction={() => {
                  setMenuOpen(false);
                }}
              />
              <NavigationItem text="Contact" onClickFunction={() => {
                setShowContact(true);
                stopVideo();
              }} />
              <div className="contact-popup-social">
                  {/*<a href="https://www.instagram.com/the_gambit_gr/" target="_blank" rel="noopener">INSTAGRAM</a>*/}
                  {/* <a href="#twitter">TWITTER</a> */}
              </div>
            </div>
          </div>
        </nav>
        <div className='menu-bottom-left-links'>
          <div className="menu-small-link">
            <NavigationItem 
              text="privacy policy"
              path="/privacy-policy"
              onClickFunction={() =>{
                setMenuOpen(false);
            }}
          />
          </div>
          <div className="menu-small-link">
            <NavigationItem 
              text="terms of use"
              path="/terms-of-use"
              onClickFunction={() =>{
                setMenuOpen(false);
              }}
            />
          </div>
        </div>
      </div>
      <Contact 
        isShowing={showContact} 
        onBottomRightButtonClick={() => {
          setShowContact(false); 
          setMenuOpen(false);
          resumeVideo();
        }} 
        onContactClose={() => {
          setShowContact(false);
          resumeVideo();
        }} 
      />
      {locationPathname.includes('/services/') &&
      <div 
        className='contact-button-fixed' 
        onClick={() => {
          setShowContact(true);
          stopVideo();
        }}>
        CONTACT US
      </div>}
    </>
  );
};

export default Navigation;
