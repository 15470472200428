import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Embarking on a culinary journey at Eleven Madison Park is a sensory odyssey"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                        <p>Embarking on a culinary journey at Eleven Madison Park is a sensory odyssey, where each dish is a masterpiece meticulously crafted to redefine the Art of fine dining.</p>
                        </div>
                        <ImageBanner text={"“Embarking on a culinary journey at Eleven Madison Park is a sensory odyssey”"} image={'/images/food/IMG_3710.jpeg'} />
                    </div>
                </>
            }
        />
    );
}