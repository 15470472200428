import React from 'react';
import Page from './Page';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import PageOutro from './PageOutro';
import VideoPlayerFull from '../components/VideoPlayerFull';
import Logo from '../components/Logo';

const Intro = () => {
  const navigate = useNavigate();

  const [showSkip, setShowSkip] = useState(false);
  const [startVideo, setStartVideo] = useState(false);
  const [url, setUrl] = useState('');
  const [mobileUrl, setMobileUrl] = useState('');
  const [introLoaded, setIntroLoaded] = useState(false);
  const [showIntroText, setShowIntroText] = useState(false);
  const [hideIntroText, setHideIntroText] = useState(false);
  const [showNextVideo, setShowNextVideo] = useState(false);
  const [hideNextVideoOverlay, setHideNextVideoOverlay] = useState(false);

  useEffect(() => {
    if (!showSkip) {
      setTimeout(function () {
        setShowSkip(true);
        gsap.to('.skip-button', {
          opacity: 1,
          duration: 0.5,
        });
      }, 3000 + 2500);
    }
  }, [showSkip]);
  
  useEffect(() => {
    if(!introLoaded) {
      setIntroLoaded(true);
      jQuery('.fullwidth-image').each(function() {
        jQuery(this).removeClass('no-opacity');
      });
    }
  }, []);

  const navigateToNext = () => {
    navigate('/services');
  };

  return (
    <>
      {/* <div className={`inner-intro active ${startVideo && 'fadeout'}`}>
        <div className='inner-intro-logo'>
          <Logo />
        </div>
        <div className='inner-intro-signature'>
          BY <strong>PANAGIOTIS SOPIADIS</strong>
        </div>
      </div> */}
      <Page
        introInstant={true}
        extraClasses="intro-page"
        content={
          <div className="content">
            <div className={`inner-intro active ${startVideo ? 'fadeout' : ''}`}>
              {/* <div className='inner-intro-logo'>
                <Logo />
              </div> */}
              {/* <div className='inner-intro-signature'>
              BY <strong>PANAGIOTIS SOPIADIS</strong>
              </div> */}
              <div>
                <img src="images/map_desktop.png" className='fullwidth-image desktop-element no-opacity' />
                <img src="images/map_mobile.png" className='fullwidth-image mobile-element no-opacity' />
              </div>
            </div>
            {!showIntroText &&
              <VideoPlayerFull
                onStart={() => {
                  setTimeout(function() {
                    setStartVideo(true);
                  }, 350);
                }}
                url='https://vimeo.com/872585819'
                urlMobile='https://vimeo.com/891381440'
                onEnd={() => {
                  setShowIntroText(true);
                  setTimeout(function() {
                    setHideIntroText(true);
                    setShowNextVideo(true);
                  }, 5000);
                }}
              />
            }
            {(!showIntroText || hideIntroText) &&
              <div className="skip-button" onClick={() => {
                  if(!showIntroText) {
                    setShowIntroText(true);
                    setTimeout(function() {
                      setShowNextVideo(true);
                    }, 5000);
                  } else {
                    navigateToNext();
                    // setIntroLoaded(true);
                  }
                }
              }>
                SKIP
              </div>
            }
            <PageOutro extraClassNames={`
                ${showIntroText && !hideIntroText ? 'loaded' : ''}
                ${hideIntroText ? 'hide-outro' : ''}
              `}
              instant={false}
              content={
                <>
                  <h2>
                    We only see the success after it’s been reached. Like a tree that
                    reaches the clouds, the roots that hold it up are invisible. They’re
                    beneath the ground, having spent years digging through the hard
                    ground to stabilize and feed the tree’s growth. It is also so with
                    business.
                  </h2>
                  <div className="quote-name">Steve Jobs</div>
                  <div className="skip-button" onClick={() => {
                    setShowNextVideo(true);
                    setHideIntroText(true);
                  }
                }>
                  SKIP
                </div>
                </>
              }
            />
            {showNextVideo && 
              <>
                <PageOutro content={<></>} extraClassNames={`${!hideNextVideoOverlay ? 'loaded' : ''} ${hideNextVideoOverlay ? 'hide-outro' : ''}`} />
                <VideoPlayerFull
                  url="https://vimeo.com/873264724"
                  urlMobile="https://vimeo.com/877892010"
                  onStart={() => {
                    setTimeout(function() {
                      setHideNextVideoOverlay(true);
                      setHideIntroText(true);
                    }, 350);
                  }}
                  onEnd={() => navigateToNext()}
                />
              </>
            }
          </div>
        }
        outro={<></>}
        // outroInstant={true}
      />
    </>
  );
};

export default Intro;
