import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import Intro from './pages/Intro';
import Bio from './pages/Bio';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Services from './pages/Services';
import Moodboard from './pages/Moodboard';
import Topics from './pages/Topics';
import BrandDevelopment from './pages/services/BrandDevelopment';
import BusinessCoaching from './pages/services/BusinessCoaching';
import BusinessExpansion from './pages/services/BusinessExpansion';
import BusinessRepresentation from './pages/services/BusinessRepresentation';
import CoBranding from './pages/services/CoBranding';
import CorporateAffairs from './pages/services/CorporateAffairs';
import CrisisManagement from './pages/services/CrisisManagement';
import ProjectManagement from './pages/services/ProjectManagement';
import QualityAssurance from './pages/services/QualityAssurance';
import RealEstateConsultancy from './pages/services/RealEstateConsultancy';
import { gsapLoader } from './gsapLoader';
import { gsapLoaderNoDelay } from './gsapLoaderNoDelay';
// import Article1 from './pages/moodboard/Article1';
import Article1 from './pages/moodboard/Article1';
import Article2 from './pages/moodboard/Article2';
import Article3 from './pages/moodboard/Article3';
import Article4 from './pages/moodboard/Article4';
import Article5 from './pages/moodboard/Article5';
import Article6 from './pages/moodboard/Article6';
import Article7 from './pages/moodboard/Article7';
import Article8 from './pages/moodboard/Article8';
import Article9 from './pages/moodboard/Article9';
import Article10 from './pages/moodboard/Article10';
import Article11 from './pages/moodboard/Article11';
import Article12 from './pages/moodboard/Article12';
import Article13 from './pages/moodboard/Article13';
import Article14 from './pages/moodboard/Article14';
import Article15 from './pages/moodboard/Article15';
import Article16 from './pages/moodboard/Article16';
import Article17 from './pages/moodboard/Article17';
import Article18 from './pages/moodboard/Article18';
import Article19 from './pages/moodboard/Article19';
import Article20 from './pages/moodboard/Article20';
import Article21 from './pages/moodboard/Article21';
import Article22 from './pages/moodboard/Article22';
import Article23 from './pages/moodboard/Article23';
import Article24 from './pages/moodboard/Article24';
import Article25 from './pages/moodboard/Article25';
import Article26 from './pages/moodboard/Article26';
import Article27 from './pages/moodboard/Article27';
import Article28 from './pages/moodboard/Article28';
import Article29 from './pages/moodboard/Article29';
import Article30 from './pages/moodboard/Article30';
import Article31 from './pages/moodboard/Article31';
import Article32 from './pages/moodboard/Article32';
// import Article25 from './pages/moodboard/Article25';
// import Article26 from './pages/moodboard/Article26';
// import Article27 from './pages/moodboard/Article27';
// import Article28 from './pages/moodboard/Article28';
// import Article29 from './pages/moodboard/Article29';
// import Article30 from './pages/moodboard/Article30';
// import Article31 from './pages/moodboard/Article31';
// import Article32 from './pages/moodboard/Article32';
// import Article33 from './pages/moodboard/Article33';
// import Article34 from './pages/moodboard/Article34';
// import Article35 from './pages/moodboard/Article35';
// import Article36 from './pages/moodboard/Article36';
// import Article37 from './pages/moodboard/Article37';
// import Article38 from './pages/moodboard/Article38';
// import Article39 from './pages/moodboard/Article39';
// import Article40 from './pages/moodboard/Article40';
// import Article41 from './pages/moodboard/Article41';
// import Article42 from './pages/moodboard/Article42';
// import Article43 from './pages/moodboard/Article43';
// import Article44 from './pages/moodboard/Article44';
// import Article45 from './pages/moodboard/Article45';
// import Article46 from './pages/moodboard/Article46';
// import Article47 from './pages/moodboard/Article47';
// import Article48 from './pages/moodboard/Article48';
// import Article49 from './pages/moodboard/Article49';
// import Article50 from './pages/moodboard/Article50';
// import Article51 from './pages/moodboard/Article51';
// import Article52 from './pages/moodboard/Article52';
// import Article53 from './pages/moodboard/Article53';
// import Article54 from './pages/moodboard/Article54';
// import Article55 from './pages/moodboard/Article55';
// import Article56 from './pages/moodboard/Article56';
// import Article57 from './pages/moodboard/Article57';
// import Article58 from './pages/moodboard/Article58';
// import Article59 from './pages/moodboard/Article59';
// import Article60 from './pages/moodboard/Article60';
// import Article61 from './pages/moodboard/Article61';
// import Article62 from './pages/moodboard/Article62';
// import Article63 from './pages/moodboard/Article63';
// import Article64 from './pages/moodboard/Article64';
// import * as All from './pages/moodboard/';
import NotFoundPage from './pages/NotFoundPage';

// Function to check if the user visited the site from a URL
const isVisitedFromURL = () => {
  // Check if there's a referrer (previous page)
  return document.referrer !== '';
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: <Home />,
        path: '/',
        // loader: gsapLoader,
      },
      {
        element: <Intro />,
        path: '/intro',
        // loader: gsapLoader,
      },
      {
        element: <Topics />,
        path: '/services',
        loader: gsapLoader,
      },
      {
        element: <Bio />,
        path: '/bio',
        loader: gsapLoader,
      },
      {
        element: <PrivacyPolicy/>,
        path: '/privacy-policy',
        loader: gsapLoader,
      },

      {
        element: <TermsOfUse/>,
        path: '/terms-of-use',
        loader: gsapLoader,
      },
      {
        element: <Moodboard />,
        path: '/moodboard',
        // loader: gsapLoader,
      },
      {
        element: <Article1 />,
        path: '/moodboard/1',
        // loader: gsapLoader,
      },
      {
        element: <Article2 />,
        path: '/moodboard/2',
        // loader: gsapLoader,
      },
      {
        element: <Article3 />,
        path: '/moodboard/3',
        // loader: gsapLoader,
      },
      {
        element: <Article4 />,
        path: '/moodboard/4',
        // loader: gsapLoader,
      },
      {
        element: <Article5 />,
        path: '/moodboard/5',
        // loader: gsapLoader,
      },
      {
        element: <Article6 />,
        path: '/moodboard/6',
        // loader: gsapLoader,
      },
      {
        element: <Article7 />,
        path: '/moodboard/7',
        // loader: gsapLoader,
      },
      {
        element: <Article8 />,
        path: '/moodboard/8',
        // loader: gsapLoader,
      },
      {
        element: <Article9 />,
        path: '/moodboard/9',
        // loader: gsapLoader,
      },
      {
        element: <Article10 />,
        path: '/moodboard/10',
        // loader: gsapLoader,
      },
      {
        element: <Article11 />,
        path: '/moodboard/11',
        // loader: gsapLoader,
      },
      {
        element: <Article12 />,
        path: '/moodboard/12',
        // loader: gsapLoader,
      },
      {
        element: <Article13 />,
        path: '/moodboard/13',
        // loader: gsapLoader,
      },
      {
        element: <Article14 />,
        path: '/moodboard/14',
        // loader: gsapLoader,
      },
      {
        element: <Article15 />,
        path: '/moodboard/15',
        // loader: gsapLoader,
      },
      {
        element: <Article16 />,
        path: '/moodboard/16',
        // loader: gsapLoader,
      },
      {
        element: <Article17 />,
        path: '/moodboard/17',
        // loader: gsapLoader,
      },
      {
        element: <Article18 />,
        path: '/moodboard/18',
        // loader: gsapLoader,
      },
      {
        element: <Article19 />,
        path: '/moodboard/19',
        // loader: gsapLoader,
      },
      {
        element: <Article20 />,
        path: '/moodboard/20',
        // loader: gsapLoader,
      },
      {
        element: <Article21 />,
        path: '/moodboard/21',
        // loader: gsapLoader,
      },
      {
        element: <Article22 />,
        path: '/moodboard/22',
        // loader: gsapLoader,
      },
      {
        element: <Article23 />,
        path: '/moodboard/23',
        // loader: gsapLoader,
      },
      {
        element: <Article24 />,
        path: '/moodboard/24',
        // loader: gsapLoader,
      },
      {
        element: <Article25 />,
        path: '/moodboard/25',
        // loader: gsapLoader,
      },
      {
        element: <Article26 />,
        path: '/moodboard/26',
        // loader: gsapLoader,
      },
      {
        element: <Article27 />,
        path: '/moodboard/27',
        // loader: gsapLoader,
      },
      {
        element: <Article28 />,
        path: '/moodboard/28',
        // loader: gsapLoader,
      },
      {
        element: <Article29 />,
        path: '/moodboard/29',
        // loader: gsapLoader,
      },
      {
        element: <Article30 />,
        path: '/moodboard/30',
        // loader: gsapLoader,
      },
      {
        element: <Article31 />,
        path: '/moodboard/31',
        // loader: gsapLoader,
      },
      {
        element: <Article32 />,
        path: '/moodboard/32',
        // loader: gsapLoader,
      },
      {
        element: <BrandDevelopment />,
        path: '/services/brand-development',
        loader: gsapLoader,
      },
      {
        element: <BusinessCoaching />,
        path: '/services/business-coaching',
        loader: gsapLoader,
      },
      {
        element: <BusinessExpansion />,
        path: '/services/business-expansion',
        loader: gsapLoader,
      },
      {
        element: <BusinessRepresentation />,
        path: '/services/business-representation',
        loader: gsapLoader,
      },
      {
        element: <CoBranding />,
        path: '/services/co-branding',
        loader: gsapLoader,
      },
      {
        element: <CorporateAffairs />,
        path: '/services/corporate-affairs',
        loader: gsapLoader,
      },
      {
        element: <CrisisManagement />,
        path: '/services/crisis-management',
        loader: gsapLoader,
      },
      {
        element: <ProjectManagement />,
        path: '/services/project-management',
        loader: gsapLoader,
      },
      {
        element: <QualityAssurance />,
        path: '/services/quality-assurance',
        loader: gsapLoader,
      },
      {
        element: <RealEstateConsultancy />,
        path: '/services/real-estate-consultancy',
        loader: gsapLoader,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      ,
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<RouterProvider router={router} />);
