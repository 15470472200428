import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Navigating the Subjective and Evolving Realm of Artistic Expression"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Art is a subjective and diverse form of human expression. It encompasses a wide range of creative activities and includes visual arts, literature, music, performance and more.</p> 
                            <p>The definition of art varies among individuals and cultures. Some see it as a reflection of beauty, emotion or skill while others appreciate its ability to provoke thought or challenge conventions. </p>
                            <p>Ultimately, the definition of Art is fluid and often personal. People's responses to aer can be deeply individual, and what speaks to one person may not have the same impact to another.</p>
                            <p>It is a dynamic and evolving concept shaped by cultural, historical and personal perspectives.  Greek Flag,  Micha Cattaui 2009. 2025</p>
                        </div>
                        <ImageBanner text={"“Navigating the Subjective and Evolving Realm of Artistic Expression”"} image={'/images/art/IMG_2025.jpeg'} />
                    </div>
                </>
            }
        />
    );
}