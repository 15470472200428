import React from 'react';
import { useNavigate } from 'react-router';
import TopicsNavigation from '../../components/TopicsNavigation';
import VideoPlayerFull from '../../components/VideoPlayerFull';
import Page from '../Page';
import { useState } from 'react';
import AfterVideo from '../../components/AfterVideo';
import PageIntro from '../PageIntro';

export default function CoBranding() {
  
  const [showNavigation, setShowNavigation] = useState(false);
  const [hideIntro, setHideIntro] = useState(false);

  return (
    <Page
      outroInstant={true}
      content={
        <>
          <div className={`video-intro ${hideIntro ? 'hide-intro' : ''}`}>
            <PageIntro content={
              <>
                <div className="topic-intro-title">
                  Co-Branding
                </div>
              </>
            } />
          </div>
          <VideoPlayerFull
            url="https://vimeo.com/872585302"
            urlMobile="https://vimeo.com/877892784"
            onEnd={() => setShowNavigation(true)}
            onStart={() => {
              setTimeout(function() {
                setHideIntro(true);
              }, 100);
            }}
          />
          <AfterVideo
            text={<>Collaborate for mutual growth</>}
            isShowing={showNavigation}
          />
          <TopicsNavigation isShown={showNavigation} />
        </>
      }
    />
  );
}
