import React from 'react';
export default function BioNavigationItemMobile({
    date,
    company,
    role,
    onClick,
    position,
    content,
    active,
    onClosedAll
}) {
    return (
        <div className={`bio-navigation-item-mobile ${active ? 'active' : ''}`}>
            <div
                className={`bio-navigation-item-mobile-clickable`}
                style={{ '--position': position }}
                onClick={onClick}
            >
                <div className="date">{date}</div>
                <div>
                    <div>{company}</div>
                    <div>{role}</div>
                </div>
            </div>
            <div className='bio-navigation-item-mobile-content'>
                {content}
                <div className='bio-navigation-item-mobile-close' onClick={onClosedAll}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="27.46" height="15.145" viewBox="0 0 27.46 15.145">
                        <path d="M6560,1539.247l12.316-12.316,12.316,12.316" transform="translate(-6558.586 -1525.517)" fill="none" stroke="#546e7a" strokeLinecap="round" strokeWidth="2"/>
                    </svg>
                </div>
            </div>
        </div>
    );
}
