import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"The Hay Adams Hotel"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Surrounded by unparalleled panoramic views of the White House, Lafayette Square, and St. John's Church, The Hay-Adams is at the epicenter of Downtown Washington, DC's most iconic destinations. Originally designed and built as a residential hotel in the 1920s, The Hay-Adams stands today as one of the most historic hotels in Washington, DC, with refined services and offerings that match our prestigious past.</p>
                            <p> With 5-star accommodations, including 124 refined guest rooms and 21 deluxe suites, exceptionally-serviced event spaces for meetings and weddings, and world-class dining destinations, The Hay-Adams is a luxury Downtown DC hotel unlike any other.</p>
                            <p>Being loyal to its promise — to exceptional guest experiences, to the environment, and to maintaining the most inviting luxury accommodations in Washington, DC— The Hay-Adams prides itself on its status as the region's most prestigious hotel destination.</p>
                        </div>
                        <ImageBanner text={"“The Hay Adams Hotel”"} image={'/images/travel/IMG_3951.jpeg'} />
                    </div>
                </>
            }
        />
    );
}