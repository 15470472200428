import React, { useEffect } from 'react';
import PageIntro from './PageIntro';
import PageOutro from './PageOutro';

export default function Page({ intro, introInstant, content, outro, outroInstant, extraClasses }) {

  return (
    <>
      {intro && <PageIntro instant={introInstant} content={intro} />}
      <div className={`page ${extraClasses !== undefined ? extraClasses : ''}`}>
        {content}
      </div>
      {outro && <PageOutro instant={outroInstant} content={outro} />}
      {outroInstant === true && (
        <div id="outro-no-delay" style={{ display: 'none' }}></div>
      )}
    </>
  );
};
