import React from 'react';

export default function MasonryItem({ image, text, date, onClick, isActive, fontSize }) {
  return (
    <div className={`masonry-item ${fontSize === 'small' ? "font-size-small" : ""} ${fontSize === 'medium' ? "font-size-medium" : ""} ${! isActive ? "inactive" : ""}`} onClick={onClick}>
      <img src={image} />
      <div className="masonry-item-overlay">
        <div className="masonry-item-overlay-inner">
          <div className="masonry-item-overlay-text">{text}</div>
          <div className='masonry-item-bottom'>
            <div className="masonry-item-overlay-date">{date}</div>
            <div className='masonry-item-go'>GO</div>
          </div>
        </div>
      </div>
    </div>
  );
}
