import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"A culinary masterpiece celebrating Peru's breathtaking diversity"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Central, Lima by Virgilio Martinez: A culinary masterpiece celebrating Peru's breathtaking diversity, where every dish is a vibrant tribute to the rich landscapes and flavors that define this extraordinary nation. </p>
                        </div>
                        <ImageBanner text={"“A culinary masterpiece celebrating Peru's breathtaking diversity”"} image={'/images/food/IMG_6964.jpeg'} />
                    </div>
                </>
            }
        />
    );
}