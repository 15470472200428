import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Where Tradition Meets Modernity"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Staying at La Mansiòn, Four Seasons Buenos Aires, a fusion of sophistication and Argentine charm, where timeless elegance meets modern luxury, is an invitation to indulge in the enchanting ambiance of this urban retreat. </p>
                        </div>
                        <ImageBanner text={"Where Tradition Meets Modernity"} image={'/images/travel/IMG_5383.jpeg'} />
                    </div>
                </>
            }
        />
    );
}