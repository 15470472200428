import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NavigationItem({ text, path, onClickFunction }) {
  let location = useLocation();
  const locationPathname = location.pathname;
  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  };
  return (
    <div
      className="nav-link"
      onClick={() => {
        onClickFunction();
        locationPathname !== path && path !== undefined
          ? navigateTo(path)
          : null;
      }}
    >
      {text}
    </div>
  );
}
