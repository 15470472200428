import React from 'react';
import { useLocation } from 'react-router';
export default function BioNavigationItem({
  date,
  company,
  role,
  onClick,
  position,
}) {
  return (
    <div
      className={`bio-navigation-item`}
      style={{ '--position': position }}
      onClick={onClick}
    >
      <div className="date">{date}</div>
      <div>
        <div>{company}</div>
        <div>{role}</div>
      </div>
    </div>
  );
}
