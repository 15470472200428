import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Construction site in Dublin Ireland"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Infusing a building with soul is the transformative touch that turns mere structure into a living, breathing entity. Construction site in Dublin Ireland</p>
                        </div>
                        <ImageBanner text={"“Construction site in Dublin Ireland”"} image={'/images/projects/IMG_8087.jpeg'} />
                    </div>
                </>
            }
        />
    );
}