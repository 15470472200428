import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Traditional food - an exploration of rich flavors that weave a narrative of heritage"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Savor the authenticity of Greek culinary traditions through rustic, traditional food - an exploration of rich flavors that weave a narrative of heritage and the Mediterranean's warm embrace.</p>
                        </div>
                        <ImageBanner text={"“Traditional food - an exploration of rich flavors that weave a narrative of heritage”"} image={'/images/food/IMG_1825.jpeg'} />
                    </div>
                </>
            }
        />
    );
}