import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Unveiling Timeless Messages in Captured Moments"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>IPhotography captures moments gone forever, yet preserves them eternally. The profound messages in each photo never cease to amaze me, offering a timeless refuge, my sanctuary of stress relief. Trikeri Village, Pilion</p>
                        </div>
                        <ImageBanner text={"“Unveiling Timeless Messages in Captured Moments”"} image={'/images/art/432C3B35.jpg'} />
                    </div>
                </>
            }
        />
    );
}