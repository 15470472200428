import * as React from 'react';
import TopicsNavigation from '../components/TopicsNavigation';
import Page from './Page';
import VideoPlayerFull from '../components/VideoPlayerFull';

const Topics = () => {
  return (
    <Page
      intro={
        <>
          <div className='topic-intro-title'>
            Services
          </div>
        </>
      }
      introInstant={true}
      outroInstant={true}
      // outro={<div>TOPICS OUTRO</div>}
      content={
        <div className="content">
          <h2>
            Please select a topic<br></br>you want us to present
          </h2>
          <TopicsNavigation />
        </div>
      }
    />
  );
};

export default Topics;
