import React from 'react';
import TopicsNavigation from '../../components/TopicsNavigation';
import VideoPlayerFull from '../../components/VideoPlayerFull';
import Page from '../Page';
import { useState } from 'react';
import AfterVideo from '../../components/AfterVideo';
import PageIntro from '../PageIntro';

export default function BrandDevelopment() {
  
  const [showNavigation, setShowNavigation] = useState(false);
  const [hideIntro, setHideIntro] = useState(false);
  
  return (
    <Page
      // introInstant={true}
      outroInstant={true}
      content={
        <>
          <div className={`video-intro ${hideIntro ? 'hide-intro' : ''}`}>
            <PageIntro content={
              <>
                <div className="topic-intro-title">
                  Brand<br></br>Development
                </div>
              </>
            } />
          </div>
          <VideoPlayerFull
            url="https://vimeo.com/872584518"
            urlMobile="https://vimeo.com/877892069"
            onEnd={() => setShowNavigation(true)}
            onStart={() => {
              setTimeout(function() {
                setHideIntro(true);
              }, 100);
            }}
            volume={0}
          />
          <AfterVideo
            isShowing={showNavigation}
            text={
              <>
                Elevate your brand's identity<br></br>for lasting impact
              </>
            }
          />
          <TopicsNavigation isShown={showNavigation} />
        </>
      }
    />
  );
}
