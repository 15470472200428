import React, {useState, useEffect} from  'react';

const CookieConsentBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    // Check if the user has already given consent
    useEffect(() => {
        const hasConsent = localStorage.getItem('cookieConsent');
        setShowBanner(hasConsent);
    }, []);

    const handleConsent = () => {
      // Set a flag in local storage to remember the user's consent
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
    };

    const handleReject = () =>{
        localStorage.setItem('Cookieconsent','false');
        alert('you have rejected the cookies');
        setShowBanner(false);
    }


    return (
    showBanner && (
    <div className="cookie-consent-banner">
        <div className="cookie-consent-banner-content">
            <h3 className="cookie-title">Cookies Policy</h3>
            <p>Our site uses cookies so that we can remember youand understand how you use our site. If you do not agree with our use of cookies, please change the current settings in the Cookie Consent Tool.</p> <p>You can also read more about cookies and similar technologies here.</p> <p>Otherwise, you agree to the use of the cookies as they are currently set.</p>
        </div>
        <div class="cookie-btns">
            <button onClick={handleConsent}>ACCEPT</button>
            <button onClick={handleReject}>DECLINE</button>
        </div>
    </div>
    )
);
    };
export default CookieConsentBanner;
