import gsap from 'gsap';

export const gsapLoader = () => {
  const tl = gsap.timeline();

  if (!document.getElementById('outro-no-delay') && !document.getElementById('intro-no-delay')) {

    // Animation to move the '.page' element
    // if(document.getElementsByClassName('.page-outro').length > 0) {
      tl.to(['.page', '.page-outro'], {
        xPercent: -100,
        duration: 0.5,
      });
    // }

    // Give time to outro to show
    // tl.to({}, { delay: 2.5 });

    // Fade out outro
    // tl.to('.page-outro:not(.instant) .inner', {
    //   opacity: 0,
    //   pointerEvents: 'none',
    //   duration: 0.5,
    // });

    // Delay for 5 milliseconds
    // tl.to(
    //   {},
    //   {
    //     delay: 0.5,
    //   }
    // );
  } else {
    // Load instantly
    // if(document.getElementsByClassName('.page-outro').length > 0) {
      tl.to(['.page', '.page-outro'], {
        xPercent: -100,
        duration: 0.5,
      });
    // }
    // tl.to(
    //   {},
    //   {
    //     delay: 0.5,
    //   }
    // );
  }

  return tl;
};
