import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"A Symphony of History and Luxury"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>A sanctuary of luxury and history, The Monasterio, a Belmond Hotel,where the sacred echos of a 16th century monastery harmonize with opulence, creating an enchanting experience in the heart of Cusco.</p>
                            <img src="/images/moodboard_article_1_content_image.jpg" />
                        </div>
                        <ImageBanner text={"A Symphony of History and Luxury"} image={'/images/travel/IMG_7989.jpeg'} />
                    </div>
                </>
            }
        />
    );
}