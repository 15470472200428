import React, { useEffect, useState } from 'react';
import './styles.css';
import './style.css';
import { Outlet } from 'react-router-dom';
import Navigation from './Navigation';
import CookieConsentBanner from './components/CookieConsentBanner';
import { useLocation, useNavigate } from 'react-router';
import Cookies from 'js-cookie';

export default function App() {
  const location = useLocation();
  const currentPage = location.pathname;
  const navigate = useNavigate();

  const [videoIsPlaying, setVideoIsPlaying] = useState(null);
  const [triedLogin, setTriedLogin] = useState(false);

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {

    // vag no login
    navigate('/');

    // if( ! triedLogin ) {
    //   setTriedLogin(true);
    //   var loginCookie = getCookie('thegambit_customer_login');
    //   if(loginCookie.length <= 0) {
    //     navigate('/');
    //   } else {
    //     jQuery.ajax({
    //       url: 'https://thegambit.gr/admin/login-authenticate-customer.php',
    //       method: 'POST',
    //       data: {
    //         password: loginCookie
    //       },
    //       success: function(response) {
    //         var status = JSON.parse(response).status;
    //         if(parseInt(status) !== 1) {
    //           navigate('/');
    //         }
    //       }
    //     });
    //   }
    // }

    const checkVideoPlaying = setInterval(function() {
      const videoStatus = Cookies.get('thegambit_video_playing');
      if(videoStatus !== 'yes') { // Video is playing
        setVideoIsPlaying(false);
      } else { // Video stopped playing
        setVideoIsPlaying(true);
      }
    }, 250);
    return () => {
      clearInterval(checkVideoPlaying);
    }

  }, []);

  return (
    <div className={`App ${currentPage.includes('services') ? 'services-page' : ''} ${!videoIsPlaying ? 'video-ended' : ''}`
    +$(currentPage.includes('privacy-policy')?'privacy-page': '')}>
      {currentPage !== null &&
        currentPage !== undefined &&
        currentPage !== '/' &&
        currentPage !== '' && <Navigation />}
      <main className={`${currentPage.includes('intro') ? 'intro-main' : ''}`}>
        <CookieConsentBanner />
        <Outlet />
      </main>
    </div>
  );
}
