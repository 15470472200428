import React, { useEffect, useState } from 'react';

export default function BackToTop({ element }) {

    const [showBackToTop, setShowBackToTop] = useState(false);

    const goToTop = () => {
        $(element).animate({ scrollTop: 0 }, "slow");
    }

    useEffect(() => {

        const handleScroll = () => {
            if (jQuery(element).scrollTop() > 50) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        jQuery(element).on('scroll', handleScroll);

        return () => {
            jQuery(element).on('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`back-to-top ${showBackToTop ? 'active' : ''}`} onClick={() => goToTop()}>
            <span>BACK</span>
            <span>TO TOP</span>
        </div>
    );

}