import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Copacabana Palace: A Timeless Legacy of Glamour and Rio's Cultural Rhythm"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p> Copacabana Palace: A historic gem where timeless glamour meets the rhythm of Rio. Since 1923, the iconic hotel has stood as a testament of luxury, hosting celebrities and dignitaries, weaving its story into the vibrant tapestry of Brazil's rich cultural heritage. </p>
                        </div>
                        <ImageBanner text={"“Copacabana Palace: A Timeless Legacy of Glamour and Rio's Cultural Rhythm”"} image={'/images/travel/IMG_3432.jpeg'} />
                    </div>
                </>
            }
        />
    );
}