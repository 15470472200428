import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Casa de Chá da Boa Nova not only tantalizes the taste buds but also captivates the eyes"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>Perched on the rugged cliffs, Casa de Chá da Boa Nova not only tantalizes the taste buds but also captivates the eyes with its architectural prowess. A harmonious fusion of modern elegance and nature's raw beauty, as if the very structure were a testament to the sublime marriage of culinary artistry and coastal grandeur.</p>
                        </div>
                        <ImageBanner text={"“Casa de Chá da Boa Nova not only tantalizes the taste buds but also captivates the eyes”"} image={'/images/food/IMG_6668.jpeg'} />
                    </div>
                </>
            }
        />
    );
}