import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"Mandarin Oriental: Elevating the Standards of Luxury Hospitality"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>A Stay at Mandarin Oriental prompts a reevaluation of luxury hospitality standards. Photo: The Mandarin Oriental Costa Navarino, Greece</p>
                        </div>
                        <ImageBanner text={"“Mandarin Oriental: Elevating the Standards of Luxury Hospitality”"} image={'/images/travel/IMG_1408.jpeg'} />
                    </div>
                </>
            }
        />
    );
}