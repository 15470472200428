import React from 'react';
import { useNavigate } from 'react-router';
import TopicsNavigation from '../../components/TopicsNavigation';
import VideoPlayerFull from '../../components/VideoPlayerFull';
import Page from '../Page';
import { useState } from 'react';
import AfterVideo from '../../components/AfterVideo';
import PageIntro from '../PageIntro';

export default function BusinessExpansion() {

  const [showNavigation, setShowNavigation] = useState(false);
  const [hideIntro, setHideIntro] = useState(false);

  return (
    <Page
      outroInstant={true}
      content={
        <>
          <div className={`video-intro ${hideIntro ? 'hide-intro' : ''}`}>
            <PageIntro content={
              <>
                <div className="topic-intro-title">
                Business<br></br>Expansion
                </div>
              </>
            } />
          </div>
          <VideoPlayerFull
            url="https://vimeo.com/872584911"
            urlMobile="https://vimeo.com/879434539"
            onEnd={() => setShowNavigation(true)}
            onStart={() => {
              setTimeout(function() {
                setHideIntro(true);
              }, 100);
            }}
          />
          {showNavigation && (
            <div className="after-video">
              <h2></h2>
            </div>
          )}
          <AfterVideo
            text={<>Scale your business<br></br>for future success</>}
            isShowing={showNavigation}
          />
          <TopicsNavigation isShown={showNavigation} />
        </>
      }
    />
  );
}
