import React from 'react';
export default function Logo() {
  return (
    <svg width="173.35" height="39.389" viewBox="0 0 173.35 39.389">
      <defs>
        <clipPath id="clip-path-logo">
          <rect width="173.35" height="39.389" fill="none" />
        </clipPath>
      </defs>
      <rect
        width="45.335"
        height="11.891"
        transform="translate(122.404 27.498)"
        fill="#fff"
      />
      <g clipPath="url(#clip-path-logo)">
        <path
          d="M169.893,7.416a3.91,3.91,0,0,1,2.252.725l1.1-2.266a4.419,4.419,0,0,0-2.649-.839A3.5,3.5,0,0,0,167.73,6.51l-.066-.023V5.15h-2.45V16.365h2.472V9.636a2.022,2.022,0,0,1,2.207-2.22m-15.958,3.354c0-1.473.221-3.354,2.1-3.354,1.92,0,2.031,2.062,2.031,3.354,0,1.427-.221,3.33-2.053,3.33-1.9,0-2.075-1.7-2.075-3.33m6.622,5.347V5.15h-2.494V6.17c-.022,0-.022.023-.044.023a3.231,3.231,0,0,0-2.539-1.156c-3.575,0-4.083,3.33-4.083,5.981,0,2.7.64,5.46,3.951,5.46a3.347,3.347,0,0,0,2.693-1.2h.044v1.065a2.2,2.2,0,0,1-2.45,2.288,4.554,4.554,0,0,1-2.494-.906c-.42.657-.883,1.314-1.3,1.971a5.408,5.408,0,0,0,3.8,1.337c2.715,0,4.922-1.45,4.922-4.916M134.578,3.043V.6H123.785V3.043h4.061V16.365h2.671V3.043Zm-22.69.023h2.671V13.9h-2.671v2.47H120.1V13.9h-2.87V3.066h2.87V.6h-8.211Zm-5.937,8.5a2.258,2.258,0,0,1-2.428,2.334h-2.869V9.319h2.869a2.193,2.193,0,0,1,2.428,2.243m-.4-6.593a2.011,2.011,0,0,1-2.274,2.039h-2.626V3.043h2.626c1.457,0,2.274.793,2.274,1.926m3.112,6.752A3.777,3.777,0,0,0,106.237,8.1V8a3.5,3.5,0,0,0,2.1-3.24c0-2.538-1.678-4.169-4.812-4.169h-5.54V16.365h6.18a4.45,4.45,0,0,0,4.5-4.644M94.54,16.365V.6H91.758L89.286,7.688H89.22L86.682.6H83.945V16.365h2.626V6.578l.066-.068,1.611,4.622h1.987l1.611-4.6h.066v9.833ZM75.116,4.335c.221,1.065.53,2.243.861,3.534.375,1.45.6,2.129.75,2.809H73.416c.132-.566.287-1.11.861-3.263.309-1.178.551-2.2.75-3.081ZM78.25,16.365h2.781L76.506.6H73.681L69.112,16.365h2.781l.927-3.4h4.5ZM61.387.483c-3.818,0-5.518,2.81-5.518,5.868V10.61c0,3.285,1.655,5.913,5.518,5.913,3.466,0,5.584-2.379,5.584-5.777V7.756H61.63v2.357h2.693v.657c0,1.88-1.037,3.194-2.914,3.194-2.119,0-2.869-1.631-2.869-3.353V6.351c0-1.654.684-3.285,2.847-3.285a2.952,2.952,0,0,1,2.825,2.107l2.472-1.043a5.367,5.367,0,0,0-5.3-3.648M38.521,16.365V13.9H31.06V9.364h6.225V6.985H31.06V3.066h7.019V.6h-9.69V16.365Zm-13.618,0V.6H22.254V7.008H16.713V.6H14.043V16.365h2.671V9.387h5.541v6.978ZM11.277,3.043V.6H.483V3.043H4.545V16.365H7.215V3.043Z"
          transform="translate(-0.223 -0.223)"
          fill="#fff"
        />
        <path
          d="M169.893,7.416a3.91,3.91,0,0,1,2.252.725l1.1-2.266a4.419,4.419,0,0,0-2.649-.839A3.5,3.5,0,0,0,167.73,6.51l-.066-.023V5.15h-2.45V16.365h2.472V9.636a2.022,2.022,0,0,1,2.207-2.22m-15.958,3.354c0-1.473.221-3.354,2.1-3.354,1.92,0,2.031,2.062,2.031,3.354,0,1.427-.221,3.33-2.053,3.33-1.9,0-2.075-1.7-2.075-3.33m6.622,5.347V5.15h-2.494V6.17c-.022,0-.022.023-.044.023a3.231,3.231,0,0,0-2.539-1.156c-3.575,0-4.083,3.33-4.083,5.981,0,2.7.64,5.46,3.951,5.46a3.347,3.347,0,0,0,2.693-1.2h.044v1.065a2.2,2.2,0,0,1-2.45,2.288,4.554,4.554,0,0,1-2.494-.906c-.42.657-.883,1.314-1.3,1.971a5.408,5.408,0,0,0,3.8,1.337c2.715,0,4.922-1.45,4.922-4.916M134.578,3.043V.6H123.785V3.043h4.061V16.365h2.671V3.043Zm-22.69.023h2.671V13.9h-2.671v2.47H120.1V13.9h-2.87V3.066h2.87V.6h-8.211Zm-5.937,8.5a2.258,2.258,0,0,1-2.428,2.334h-2.869V9.319h2.869a2.193,2.193,0,0,1,2.428,2.243m-.4-6.593a2.011,2.011,0,0,1-2.274,2.039h-2.626V3.043h2.626c1.457,0,2.274.793,2.274,1.926m3.112,6.752A3.777,3.777,0,0,0,106.237,8.1V8a3.5,3.5,0,0,0,2.1-3.24c0-2.538-1.678-4.169-4.812-4.169h-5.54V16.365h6.18a4.45,4.45,0,0,0,4.5-4.644M94.54,16.365V.6H91.758L89.286,7.688H89.22L86.682.6H83.945V16.365h2.626V6.578l.066-.068,1.611,4.622h1.987l1.611-4.6h.066v9.833ZM75.116,4.335c.221,1.065.53,2.243.861,3.534.375,1.45.6,2.129.75,2.809H73.416c.132-.566.287-1.11.861-3.263.309-1.178.551-2.2.75-3.081ZM78.25,16.365h2.781L76.506.6H73.681L69.112,16.365h2.781l.927-3.4h4.5ZM61.387.483c-3.818,0-5.518,2.81-5.518,5.868V10.61c0,3.285,1.655,5.913,5.518,5.913,3.466,0,5.584-2.379,5.584-5.777V7.756H61.63v2.357h2.693v.657c0,1.88-1.037,3.194-2.914,3.194-2.119,0-2.869-1.631-2.869-3.353V6.351c0-1.654.684-3.285,2.847-3.285a2.952,2.952,0,0,1,2.825,2.107l2.472-1.043a5.367,5.367,0,0,0-5.3-3.648M38.521,16.365V13.9H31.06V9.364h6.225V6.985H31.06V3.066h7.019V.6h-9.69V16.365Zm-13.618,0V.6H22.254V7.008H16.713V.6H14.043V16.365h2.671V9.387h5.541v6.978ZM11.277,3.043V.6H.483V3.043H4.545V16.365H7.215V3.043Z"
          transform="translate(-0.223 -0.223)"
          fill="#fff"
        />
        <path
          d="M169.893,7.416a3.91,3.91,0,0,1,2.252.725l1.1-2.266a4.419,4.419,0,0,0-2.649-.839A3.5,3.5,0,0,0,167.73,6.51l-.066-.023V5.15h-2.45V16.365h2.472V9.636A2.022,2.022,0,0,1,169.893,7.416Zm-15.958,3.354c0-1.473.221-3.354,2.1-3.354,1.92,0,2.031,2.062,2.031,3.354,0,1.427-.221,3.33-2.053,3.33C154.111,14.1,153.935,12.4,153.935,10.769Zm6.622,5.347V5.15h-2.494V6.17c-.022,0-.022.023-.044.023a3.231,3.231,0,0,0-2.539-1.156c-3.575,0-4.083,3.33-4.083,5.981,0,2.7.64,5.46,3.951,5.46a3.347,3.347,0,0,0,2.693-1.2h.044v1.065a2.2,2.2,0,0,1-2.45,2.288,4.554,4.554,0,0,1-2.494-.906c-.42.657-.883,1.314-1.3,1.971a5.408,5.408,0,0,0,3.8,1.337C158.349,21.032,160.557,19.583,160.557,16.116ZM134.578,3.043V.6H123.785V3.043h4.061V16.365h2.671V3.043Zm-22.69.023h2.671V13.9h-2.671v2.47H120.1V13.9h-2.87V3.066h2.87V.6h-8.211Zm-5.937,8.5a2.258,2.258,0,0,1-2.428,2.334h-2.869V9.319h2.869A2.193,2.193,0,0,1,105.95,11.562Zm-.4-6.593a2.011,2.011,0,0,1-2.274,2.039h-2.626V3.043h2.626C104.736,3.043,105.553,3.836,105.553,4.969Zm3.112,6.752A3.777,3.777,0,0,0,106.237,8.1V8a3.5,3.5,0,0,0,2.1-3.24c0-2.538-1.678-4.169-4.812-4.169h-5.54V16.365h6.18A4.45,4.45,0,0,0,108.666,11.721ZM94.54,16.365V.6H91.758L89.286,7.688H89.22L86.682.6H83.945V16.365h2.626V6.578l.066-.068,1.611,4.622h1.987l1.611-4.6h.066v9.833ZM75.116,4.335c.221,1.065.53,2.243.861,3.534.375,1.45.6,2.129.75,2.809H73.416c.132-.566.287-1.11.861-3.263.309-1.178.551-2.2.75-3.081ZM78.25,16.365h2.781L76.506.6H73.681L69.112,16.365h2.781l.927-3.4h4.5ZM61.387.483c-3.818,0-5.518,2.81-5.518,5.868V10.61c0,3.285,1.655,5.913,5.518,5.913,3.466,0,5.584-2.379,5.584-5.777V7.756H61.63v2.357h2.693v.657c0,1.88-1.037,3.194-2.914,3.194-2.119,0-2.869-1.631-2.869-3.353V6.351c0-1.654.684-3.285,2.847-3.285a2.952,2.952,0,0,1,2.825,2.107l2.472-1.043A5.367,5.367,0,0,0,61.387.483ZM38.521,16.365V13.9H31.06V9.364h6.225V6.985H31.06V3.066h7.019V.6h-9.69V16.365Zm-13.618,0V.6H22.254V7.008H16.713V.6H14.043V16.365h2.671V9.387h5.541v6.978ZM11.277,3.043V.6H.483V3.043H4.545V16.365H7.215V3.043Z"
          transform="translate(-0.223 -0.223)"
          fill="none"
          stroke="#fff"
          strokeWidth="0.966"
        />
      </g>
    </svg>
  );
}
