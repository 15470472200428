import React from 'react';
import { useState, useEffect } from 'react';
import BioNavigation from '../components/BioNavigation';
import Page from './Page';
import BioDrawerSection from '../components/BioDrawerSection';
import BioNavigationMobile from '../components/BioNavigationMobile';

const Bio = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(-1);

  const changeSection = (sectionIndex) => {
    if (sectionIndex !== activeSection) {
      setDrawerOpen(false);
      setTimeout(function () {
        setActiveSection(sectionIndex);
        if (sectionIndex > -1) {
          setDrawerOpen(true);
          if (jQuery('header').hasClass('menu-invert')) {
            jQuery('header').removeClass('menu-invert');
          }
        } else {
          setDrawerOpen(false);
          if (!jQuery('header').hasClass('menu-invert')) {
            jQuery('header').addClass('menu-invert');
          }
        }
      }, 700);
    } else {
      setDrawerOpen(false);
      setTimeout(function () {
        setActiveSection(-1);
        if (!jQuery('header').hasClass('menu-invert')) {
          jQuery('header').addClass('menu-invert');
        }
      }, 700);
    }
  };



  const changeSectionMobile = (sectionIndex) => {
    if (sectionIndex !== activeSection) {
      setDrawerOpen(false);
      setActiveSection(sectionIndex);
      if (sectionIndex > -1) {
        setDrawerOpen(true);
        if (jQuery('header').hasClass('menu-invert')) {
          jQuery('header').removeClass('menu-invert');
        }
      } else {
        setDrawerOpen(false);
        if (!jQuery('header').hasClass('menu-invert')) {
          jQuery('header').addClass('menu-invert');
        }
      }
    } else {
      setDrawerOpen(false);
      setActiveSection(-1);
      if (!jQuery('header').hasClass('menu-invert')) {
        jQuery('header').addClass('menu-invert');
      }
    }
  };


  const onClosedAll = () => {
    setActiveSection(-1);
  }


  const setBioDrawerHeight = () => {
    var drawerHeight = window.innerHeight;
    var navigationBarHeight = document.querySelector('.bio-navigation-container').offsetHeight;
    document.querySelector('.bio-info').style.height = drawerHeight - navigationBarHeight + 'px';
    document.querySelector('.bio-info').style.marginTop = '-' + navigationBarHeight + 'px';
    document.querySelector('.bio-info .initial-content').style.height = drawerHeight - navigationBarHeight + 'px';
    document.querySelector('.bio-info .bio-drawer').style.height = drawerHeight - navigationBarHeight + 'px';
    const h2Elements = document.querySelectorAll('.bio-drawer-title');
    const marginTopValue = `calc(${document.querySelector('.initial-content > h2').offsetHeight}px - var(--pdng) / 2)`;
    for (let i = 0; i < h2Elements.length; i++) {
      h2Elements[i].style.marginTop = marginTopValue;
    }
  }


  const mobileMenuScroll = () => {
    var distanceScrolled = jQuery('.content').scrollTop();
    var windowWidth = jQuery(window).width();
    var header = jQuery('header');
    if (distanceScrolled > 100 && windowWidth < 1200) {
      header.removeClass('transparent');
    } else {
      header.addClass('transparent');
    }
  }

  useEffect(() => {
    if (!jQuery('header').hasClass('menu-invert')) {
      jQuery('header').addClass('menu-invert');
    }
    window.addEventListener('resize', setBioDrawerHeight);
    setBioDrawerHeight();
    mobileMenuScroll();
    jQuery('.content').on('scroll', mobileMenuScroll);
    return () => {
      window.removeEventListener('resize', setBioDrawerHeight);
      jQuery('.content').unbind('scroll', mobileMenuScroll);
    }
  }, []);

  return (
    <Page
      // intro={<>
      //   <div className="topic-intro-title">
      //     Bio
      //   </div>
      // </>}
      introInstant={true}
      content={
        <>
          <div className="content bio">
            <div className="bio-image" >
              <img src="/images/test-bio.jpg" />
            </div>
            <div className={`bio-info ${drawerOpen ? 'active' : ''}`}>
              <div className="initial-content">
                <h2>Bio</h2>
                <div className='fixed-content-bottom'>
                  <div className='initial-content-subtitle'>
                    {/* “We can’t leave anything to luck. Our operation has to run as
                    close to perfection as possible with unique offerings around
                    the clock.” */}
                    There are few people who know the worlds of luxury living and hospitality like Panagiotis Sopiadis. From hands-on operational and senior leadership roles in hotels in Greece, Cyprus and the United States, to penning profiles, thought-leadership pieces and in-the-know reviews as a magazine writer and editor, his insights are second to none.
                    <br></br>
                    <br></br>
                    Panagiotis founded The Gambit gr in 2020 with the aim of using his talents and extensive network to help businesses position themselves in the strongest ways possible, offering fully comprehensive consultation services ranging from business development to destination management, brand identity, complete project management, and more.
                    <br></br>
                    <br></br>
                    It's all a far cry from the path he was – almost – destined to set out on, following in two generations of his family's footsteps in the ranks of Greek Police. Instead, he enrolled in hotel school, became increasingly fascinated by the intricacies of the world of hospitality. From the special choreography that keeps front of house and back of house teams operating in harmony, to the tiniest elements that can make a guest's stay the best of their life, or can destroy the magic in an instant, he found himself drawn to all of it.
                    <br></br>
                    <br></br>
                    "All it takes is a bad cup of coffee to ruin a perfect time," he says. "We shouldn't allow that."
                    <br></br>
                    <br></br>
                    Whether he's casting a sharp eye over a business plan, curating an art collection, or building a launch strategy, Panagiotis's savvy head for marketing and clear understanding of the dynamics of today's world in Greece and on an international level make him an ideal partner and advisor for businesses looking to establish a presence and grow in the world of luxury.
                  </div>
                  {/* <div className='initial-content-list'>
                    <div>
                      <div>Title:</div>
                      <div>Business Development Consultant</div>
                    </div>
                    <div>
                      <div>Birthplace:</div>
                      <div>Thessaloniki, Greece</div>
                    </div>
                    <div>
                      <div>Education:</div>
                      <div>
                        Hospitality Administration/Management, Cornell University
                      </div>
                    </div>
                    <div>
                      <div>College for Tourism & Hotel Management,</div>
                      <div>xxxxxx GREECE</div>
                    </div>
                    <div>
                      <div>Languages:</div>
                      <div>Greek, English</div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className={`desktop-element bio-drawer ${drawerOpen ? 'active' : ''}`}>
                <div className='fixed-content-bottom'>
                  <BioDrawerSection
                    isActive={activeSection === 0}
                    content={
                      <>
                        <div className='bio-drawer-title'>
                          The Gambit gr
                        </div>
                        <div className='bio-drawer-subtitle'>
                          celebrates  the completion of 10 projects
                        </div>
                        <div className='bio-drawer-subtitle-small'>

                        </div>
                        <div className='bio-drawer-text'>
                          In 2023, The Gambit GR achieved its first milestone. By the end of the year, we celebrated our successful participation in 10 projects across Hospitality, Real Estate, Luxury Retail, Business Coaching and Arts. These fields were assigned to us by independent owners, investors, funds and family offices in Greece, Cyprus, UK & USA.
                        </div>
                      </>
                    }
                  />
                  <BioDrawerSection
                    isActive={activeSection === 1}
                    content={
                      <>
                        <div className='bio-drawer-title'>
                          FNL
                        </div>
                        <div className='bio-drawer-subtitle'>
                          Partner<br></br>January 2022 - Present
                        </div>
                        <div className='bio-drawer-subtitle-small'>
                          Athens, Attiki, Greece
                        </div>
                        <div className='bio-drawer-text'>
                          The FnL Guide, is a luxury lifestyle guide in Greece covering various aspects such as bars, restaurants, hotels, travel, retail and more since 2011. An online source of the most accurate and up-to-date details on luxury experiences in Greece.
                        </div>
                      </>
                    }
                  />
                  <BioDrawerSection
                    isActive={activeSection === 2}
                    content={
                      <>
                        <div className='bio-drawer-title'>
                          The Gambit gr
                        </div>
                        <div className='bio-drawer-subtitle'>
                          Founder<br></br>
                        </div>
                        <div className='bio-drawer-subtitle-small'>

                        </div>
                        <div className='bio-drawer-text'>
                          A white label brand development agency was born.
                          The Gambit Gr is a business partner, a brain-storming
                          channel that filters all processes, a third eye that provides feedback
                          through every step of the way.
                          The Gambit gr vision -shaped by personal journeys and professional
                          experiences- is to become the reliable partner in every new opportunity, <br></br><br></br>to be part of the long-term and sustainable<br></br><br></br>

                          development of your brand. A team of specialists in
                          their fields is always at your disposal for consultation
                          or for complete project management
                        </div>
                      </>
                    }
                  />
                  <BioDrawerSection
                    isActive={activeSection === 3}
                    content={
                      <>
                        <div className='bio-drawer-title'>
                          Master's Degree
                        </div>
                        <div className='bio-drawer-subtitle'>
                          Hospitality Management<br></br>
                        </div>
                        <div className='bio-drawer-subtitle-small'>
                          Cornell University
                        </div>
                        <div className='bio-drawer-text'>
                          The Master of Management in Hospitality (MMH) program is a graduate-level business program firmly anchored in the hospitality industry.  The MMH curriculum focuses on understanding and experiencing the business of hospitality management, rather than general management.<br></br><br></br>

                          Cornell is a privately endowed research university and a partner of the State University of New York. Ithaca, NY. Founded 1865
                        </div>
                      </>
                    }
                  />
                  <BioDrawerSection
                    isActive={activeSection === 4}
                    content={
                      <>
                        <div className='bio-drawer-title'>
                          Four Seasons Astir Palace
                        </div>
                        <div className='bio-drawer-subtitle'>
                          Pre opening team, Hotel Manager<br></br>October 2017 - November 2020
                        </div>
                        <div className='bio-drawer-subtitle-small'>
                          Athens, Attiki, Greece
                        </div>
                        <div className='bio-drawer-text'>
                          A multi-million euro revamp of the luxury Astir Palace hotel on the Athens Riviera brought the Four Seasons brand to Greece for the first time. The hotel, bigest Four Seasons in EMEA, includes 300 rooms, suites and executive bungalows, first-class spa facilities, fitness centers and tennis academy, a state-of-the-art conference center, two outdoor pools, one indoor pool, and eight eateries, lounges and bars.<br></br><br></br>

                          The 5-star hotel is situated in a perfect location, for travelers who desire the excitement of the city but enjoy the seclusion of private beaches on a sunny peninsula. The Four Seasons is built on 75 acres of land, filled with Greek hospitality.
                        </div>
                      </>
                    }
                  />
                  <BioDrawerSection
                    isActive={activeSection === 5}
                    content={<>
                      <div className='bio-drawer-title'>
                        Bill & Coo Mykonos <br></br>& The Beefbar
                      </div>
                      <div className='bio-drawer-subtitle'>
                        General Manager<br></br>December 2013 - November 2017
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Mykonos, Greece<br></br>
                        2014 October, Bill & Coo, Best Coastal Boutique Hotel, Europe, Boutique Hotel Awards, London<br></br>
                        2016 February, Bill & Coo, Best Boutique Hotel, World Tourism Forum, Istanbul<br></br>
                        2017 Opening The Beefbar Mykonos

                      </div>
                      <div className='bio-drawer-text'>
                        The opening of the first Bill & Coo hotel, Bill & Coo Suites and Lounge, in 2006 on the edge of Mykonos Town with its views over the Aegean Sea and incredible sunsets, brought a new level of intimate luxury to the island. The hotel aimed, and very soon achieved, to become the place where the guest would be at the centre of everything, where every experience would be tailored individually. In 2016, Bill & Coo expanded to a second property, the adults-only Bill & Coo Coast Suites, located on a beautiful golden stretch of Agios Ioannis beach, just a seven-minute drive from Bill & Coo Suites and Lounge. This intimate hideaway opened up a new way for guests to enjoy the Bill & Coo way of life in a space designed for tranquil relaxation.
                      </div>
                    </>}
                  />
                  {/* <BioDrawerSection
                    isActive={activeSection === 6}
                    content={<>
                      <div className='bio-drawer-title'>
                        Magazines & sites
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Travel, Food & Art Editor<br></br>October 2007 - September 2015
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Greece
                      </div>
                      <div className='bio-drawer-text'>
                        Lorem Ipsum is simply dummy text of t he printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                      </div>
                    </>}
                    /> */}
                  <BioDrawerSection
                    isActive={activeSection === 7}
                    content={<>
                      <div className='bio-drawer-title'>
                        Chandris Hotels
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Executive Assistant Manager<br></br>October 2011 - March 2013
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Metropolitan Hotel, Athens GR
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Executive Assistant Manager<br></br>April 2008 - September 2011
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        THE MET Hotel, Thessaloniki
                      </div>
                      <div className='bio-drawer-text'>
                        For over 60 years, Chandris Hotels uphold the famous Greek concept of hospitality providing their guests with exceptional service and unforgettable experiences. Continuing this tradition, the brand ensures excellence and offers the highest standards on every conceivable level for their guests. With a passion for detail and personal attention to each visitor's needs, Chandris Hotels welcome their esteemed guests to a sophisticated combination of high aesthetics and technology, discreet luxury and relaxation. We blend your hotel experience with local history and deep cultural values.
                      </div>
                    </>}
                  />
                  <BioDrawerSection
                    isActive={activeSection === 8}
                    content={<>
                      <div className='bio-drawer-title'>
                        The Hay-Adams Hotel
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Operations Manager<br></br>January 2004 - December 2005
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Washington D.C.
                      </div>
                      <div className='bio-drawer-text'>
                        Surrounded by unparalleled panoramic views of the White House, Lafayette Square, and St. John's Church, The Hay-Adams is at the epicenter of Downtown Washington, DC’s most iconic destinations. Originally designed and built as a residential hotel in the 1920s, The Hay-Adams stands today as one of the most historic hotels in Washington, DC, with refined services and offerings that match our prestigious past. With 5-star accommodations, including 124 refined guest rooms and 21 deluxe suites, exceptionally-serviced event spaces for meetings and weddings, and world-class dining destinations, The Hay-Adams is a luxury Downtown DC hotel unlike any other. Being loyal to its promise — to exceptional guest experiences, to the environment, and to maintaining the most inviting luxury accommodations in Washington, DC— The Hay-Adams prides itself on its status as the region’s most prestigious hotel destination.
                      </div>
                    </>}
                  />
                  {/* <BioDrawerSection
                    isActive={activeSection === 9}
                    content={<>
                      <div className='bio-drawer-title'>
                        Helios Hotels & Resorts
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Front Office Manager<br></br>December 2002 - November 2003
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Nafplion, Greece
                      </div>
                      <div className='bio-drawer-text'>
                        Lorem Ipsum is simply dummy text of t he printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                      </div>
                    </>}
                    /> */}
                  <BioDrawerSection
                    isActive={activeSection === 10}
                    content={<>
                      <div className='bio-drawer-title'>
                        Imaret
                      </div>
                      <div className='bio-drawer-subtitle'>
                        <br></br>
                      </div>
                      <div className='bio-drawer-subtitle-small'>

                      </div>
                      <div className='bio-drawer-text'>
                        An intimate luxurious hospitality property, with only 18 keys, located within the homonymous historical monument. Built in 1817 right in the middle of the historical district of Kavala, in Northern Greece, it is an unexpected oasis of tranquility and superior service. The monument is a rare example in Europe, a masterpiece of late Ottoman architecture. The variety of decoration, the quality of the delicate structures, the interaction between the indoor and outdoor spaces, and the adaptability of the functional structures, create an original complex of high aesthetic and cultural value. Each detail is a tribute to absolute luxury. Lavish materials, all specially made, changing from rich velvet and silks in the winter to elegant handmade French linen in summer, handmade oriental carpets covering waxed chestnut floors. A large variety of exclusive toiletries and candles create Imaret's indisputably essential atmosphere.
                      </div>
                    </>}
                  />
                  <BioDrawerSection
                    isActive={activeSection === 11}
                    content={<>
                      <div className='bio-drawer-title'>
                        Tokyo - Seoul
                      </div>
                      <div className='bio-drawer-subtitle'>
                        <br></br>
                      </div>
                      <div className='bio-drawer-subtitle-small'>

                      </div>
                      <div className='bio-drawer-text'>
                        Two Years of experience in the hospitality industry in Asia, primarily in Tokyo, Japan and Seoul, South Korea, across various enterprises. Immersed in a unique blend of traditions, rich history, profound knowledge and cutting edge technology.
                      </div>
                    </>}
                  />
                  <BioDrawerSection
                    isActive={activeSection === 12}
                    content={<>
                      <div className='bio-drawer-title'>
                        The Hay Adams Hotel
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Operations Manager
                      </div>
                      <div className='bio-drawer-subtitle-small'>

                      </div>
                      <div className='bio-drawer-text'>
                        Surrounded by unparalleled panoramic views of the White House, Lafayette Square, and St. John's Church, The Hay-Adams is at the epicenter of Downtown Washington, DC's most iconic destinations. Originally designed and built as a residential hotel in the 1920s, The Hay-Adams stands today as one of the most historic hotels in Washington, DC, with refined services and offerings that match our prestigious past. With 5-star accommodations, including 124 refined guest rooms and 21 deluxe suites, exceptionally-serviced event spaces for meetings and weddings, and world-class dining destinations, The Hay-Adams is a luxury Downtown DC hotel unlike any other. Being loyal to its promise — to exceptional guest experiences, to the environment, and to maintaining the most inviting luxury accommodations in Washington, DC— The Hay-Adams prides itself on its status as the region's most prestigious hotel destination.
                      </div>
                    </>}
                  />
                  <BioDrawerSection
                    isActive={activeSection === 13}
                    content={<>
                      <div className='bio-drawer-title'>
                        Amphitryon Hotel
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Front Office Manager <br></br>December 2002 - November 2003
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Nafplion, Greece
                      </div>
                      <div className='bio-drawer-text'>
                        A contemporary boutique hotel in Nafplio, the first capital of
                        Modern Greece, renowned for lodging famous visitors from all
                        over the world. Perched near the castle of Akronafplia, the
                        Amphitryon is a hotel of absolute beauty. There are 42 luxury
                        rooms and three suites, all overlooking the bay of Nafplion
                        and the island of Bourtzi, all with great amenities like remote-
                        controlled mattresses, private sea view terraces and
                        mouthwatering breakfast.
                      </div>
                    </>}
                  />
                  <BioDrawerSection
                    isActive={activeSection === 14}
                    content={<>
                      <div className='bio-drawer-title'>
                        Annabelle Hotel,<br></br>Thanos Hotels Group
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Assistant Front Office Manager<br></br>May 2002 - November 2002
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Paphos, Cyprus
                      </div>
                      <div className='bio-drawer-text'>
                        Under the soulful care of the Michaelides family since 1985, Annabelle shines as the grand dame of 5-star hotels in Paphos. Annabelle epitomises the warmth of Cyprus, the beauty of its nature, and its hands-on culture of care and conscientiousness.
                      </div>
                    </>}
                  />
                  <BioDrawerSection
                    isActive={activeSection === 15}
                    content={<>
                      <div className='bio-drawer-title'>
                        Vedema Resort
                      </div>
                      <div className='bio-drawer-subtitle'>
                        Guest Experience Manager<br></br>
                      </div>
                      <div className='bio-drawer-subtitle-small'>
                        Santorini, Greece
                      </div>
                      <div className='bio-drawer-text'>
                        Vedema Resort, located in the unspoiled village of Megalochori, inspires guests to fully immerse into the simple, slow-paced life of the island and addresses global explorers seeking to experience the island like locals do.<br /><br />
                        Remaining true to its promise to offer understated, yet transformative luxury, Vedema is the ultimate and authentic village retreat.
                      </div>
                    </>}
                  />

                </div>
              </div>
            </div>
            <BioNavigationMobile onChangeSection={changeSectionMobile} activeSection={activeSection} onClosedAll={() => onClosedAll()} />
          </div>
          <div className="bio-navigation-container desktop-element">
            <BioNavigation onChangeSection={changeSection} />
          </div>
        </>
      }
      outroInstant={true}
    />
  );
};

export default Bio;
