import React from "react";
import Article from "../../components/Article";
import ImageBanner from "../../components/ImageBanner";

export default function Article1() {
    return (
        <Article 
            title={"The Enduring Allure and Unstoppable Energy of New York City"}
            featuredImage={'/images/moodboard_article_1_featured_image.jpg'}
            content={
                <>
                    <div className="row columns-55-45">
                        <div className="column">
                            <p>The Energy of New York, with its iconic skyline, diverse people and constant street noise, in an experience that leaves you profoundly touched. Photo: The Standard NY, USA</p>
                        </div>
                        <ImageBanner text={"“The Enduring Allure and Unstoppable Energy of New York City”"} image={'/images/travel/IMG_2784.jpeg'} />
                    </div>
                </>
            }
        />
    );
}